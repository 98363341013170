import React from 'react'
// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../__redux/actions'
import { withRouter } from 'react-router-dom'
//components
import Initials from '../elements/Initials'

class MessagesComponent extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            event: null,
            conversationsList: [],
            error: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        this._isMounted = true
        let that = this

        fetch(`/api/messages/conversations`, {
            method: 'GET',
            // attach token/cookie
            credentials: "same-origin",
        }).then(response => (response.json()))
            .then((result) => {
                if (result.error) {
                    that.setState({ error: result.error })
                }
                if (this._isMounted) {
                    that.setState({ conversationsList: result.sort((a, b) => (b.unreadMessagesCount - a.unreadMessagesCount)) })
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    onConversationClick(conversation) {
        let updatedRecipientUserId = conversation.recipientUserId != this.props.user._id ?
            conversation.recipientUserId : conversation.creatorId

        if (conversation.recipientUserId) {
            return this.props.history.push(`/chat?` +
                `eventId=${conversation.eventId}&` +
                `activityId=${conversation.activityId}&` +
                `recipientId=${updatedRecipientUserId}&` +
                `from=messages`)
        }
        if (conversation.groupId) {
            return this.props.history.push(`/chat?` +
                `eventId=${conversation.eventId}&` +
                `activityId=${conversation.activityId}&` +
                `groupId=${conversation.groupId}&` +
                `from=messages`)
        }
    }

    render() {
        let p = this.props
        let s = this.state

        return (
            <div className='MessagesComponent'>
                <div className="subContainer20">
                    <i className='mywanderLogo' />
                    <Initials right
                        name={p.user.name} />
                    <i className='questionmark blue question'
                        onClick={() => { p.history.push(`/feedback?` + `from=messages`) }} />
                    <h1 className="sides20">Messages</h1>

                    {s.conversationsList.length > 0 ?
                        <ul className='conversations'>
                            {s.conversationsList.map((conversation, i) => (
                                <li key={i}
                                    className='conversation'
                                    onClick={this.onConversationClick.bind(this, conversation)}>

                                    <span className='eventName'>{conversation.eventName} -</span>
                                    <span className='activityName'>{conversation.activityName}</span>

                                    <div className='nameAndText'>
                                        <span>{conversation.creatorName}:</span>
                                        <span className='text'>{conversation.text}</span>
                                    </div>
                                    <div className='countCircle'>
                                        <span className='count'>{conversation.unreadMessagesCount}</span>
                                    </div>
                                </li>
                            ))
                            }
                        </ul>
                        :
                        <div className='noMessages'>You have no messages</div>
                    }
                    <div className='bottomPusher' />
                </div>

                <div className="footer">
                    <button
                        className='center ghost circular'
                        onClick={() => { p.history.push('/profile') }}>
                        <i className="left blue arrow" />
                    </button>
                </div>
            </div >
        )
    }
}

let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessagesComponent))
