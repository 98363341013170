// libraries
import React from 'react'

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../../__redux/actions'
import { withRouter } from 'react-router-dom'

// components
import Initials from '../../elements/Initials'

class SelectContactElement extends React.Component {

    render() {
        let p = this.props

        return (
            <div className='SelectContactElement'>
                <div className="subContainer20">
                    <i className='mywanderLogo' />
                    <Initials right
                        name={p.user.name} />
                    <h1 className='sides20'>{p.title}</h1>

                    {p.contacts.length > 0 ?
                        <ul className='contacts'>
                            {p.contacts.map((contact, i) =>

                                <li key={i}
                                    onClick={p.onContactClick.bind(this, contact.participantUserId)}
                                    className={'participant' + (p.selectedContacts.includes(contact.participantUserId) ? ' active' : '')}>
                                    <h4 className='thin name'>
                                        {contact.participantName}
                                    </h4>
                                </li>

                            )}
                        </ul> :
                        <div className='noContactsMs'>No participants available</div>
                    }
                    <div className='bottomPusher' />
                </div>

                <div className="footer">
                    <button
                        className='left ghost circular'
                        onClick={() => p.onBackClick()}>
                        <i className="left blue arrow" />
                    </button>

                    <button
                        className={"right" + p.rightButton.additionalClass}
                        onClick={() => p.rightButton.click()}>
                        <span>{p.rightButton.title}</span>
                    </button>
                </div>
            </div >
        )
    }
}

let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectContactElement))