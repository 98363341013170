//libraries
import React from 'react'
import moment from 'moment'
import ReactGA from 'react-ga';

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../../__redux/actions'
import { withRouter } from 'react-router-dom'

//components
import Input from '../../elements/Input'
import Initials from '../../elements/Initials'
import DateTimeElement from '../../elements/DateTime'
import PlacesAutocomplete from 'react-places-autocomplete';

class CreateEditActivity extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            activityName: '',
            location: '', // formatted string like "3507 Palmilla Dr. San Jose, CA, USA"
            placesList: [],
            startDate: moment().set('hour', 9).set('minute', 0),
            finishDate: moment().add(1, 'days').set('hour', 18).set('minute', 0),
            costPerson: '',
            totalCost: '',
            countOfParticipants: null,
            eventCreatorId: '',
            updateIsClicked: false,
            error: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true

        let activityId = this.props.match.params.activityId
        let eventId = this.props.match.params.eventId

        if (activityId) {
            fetch(`/api/activities/${activityId}`, {
                method: 'GET',
                credentials: 'same-origin'
            }).then(response => response.json())
                .then(result => {
                    result.activityName = result.name
                    delete result.name
                    if (result.error && result.error.key === 'unauthorized') {
                        // Cookies.remove('token'); // TODO: bring back once stable
                    } else {
                        if (this._isMounted) {
                            result.startDate = moment(result.startDate)
                            result.finishDate = moment(result.finishDate)
                            this.setState(Object.assign(this.state, result))
                        }
                    }
                })

            fetch(`/api/activitiesParticipants/${activityId}/count`, {
                method: 'GET',
                credentials: 'same-origin',
            })
                .then(response => response.json())
                .then((participantCountResult) => {
                    if (this._isMounted) {
                        let count = participantCountResult + ''
                        let costPerson = this.state.costPerson.replace(/[^0-9]/gm, '')
                        let totalCost = (costPerson * count)

                        this.setState({
                            countOfParticipants: participantCountResult,
                            totalCost: totalCost + ''
                        })
                    }
                })
        }

        fetch(`/api/events/${eventId}`, {
            method: 'GET',
            credentials: 'same-origin',
        }).then(response => response.json())
            .then((EventResult) => {
                let eventCreatorId = EventResult.creator
                if (this._isMounted) { this.setState({ eventCreatorId }) }
            })
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    // checking for any value changes in inputs
    onActivityInputsChange(type, value) {
        let s = this.state
        let newState = { [type]: value }

        if (type === 'totalCost') {
            let totValue = +value.replace(/[^0-9]/gm, '')
            newState.costPerson = (totValue / s.countOfParticipants).toFixed(2)
        }
        if (type === 'costPerson') {
            let costPerson = +value.replace(/[^0-9]/gm, '')
            newState.totalCost = (costPerson * s.countOfParticipants).toFixed(2)
        }
        // if any value changes in any of the type state, it will update the state
        this.setState(newState)
    }

    onDateChange(date) {
        this.setState({
            startDate: date
        });
    }

    onAddUpdateActivityClick() {
        let s = this.state
        let p = this.props
        let that = this
        let activityId = p.match.params.activityId
        let eventId = p.match.params.eventId

        let payload = {
            eventId,
            name: s.activityName,
            location: s.location,
            startDate: s.startDate.toDate(),
            finishDate: s.finishDate.toDate()
        }

        if (s.costPerson !== '') payload.costPerson = s.costPerson
        if (s.totalCost !== '') payload.totalCost = s.totalCost

        if (s.eventCreatorId === p.user._id) {
            // relative path that will be added to tail of domain to make a call
            fetch(`/api/activities/` + (activityId ? activityId + '/update' : 'new'), {
                method: activityId ? 'PUT' : 'POST',
                // only authorized users, attach token/cookie
                credentials: "same-origin",
                headers: { "Content-Type": 'application/json' },
                // information/ payload being sent to server
                body: JSON.stringify(payload)
            }).then(response => (response.json()))
                // response from server
                .then((result) => {
                    // if response has errors
                    if (result.error) {
                        // update error state
                        that.setState({ error: result.error })
                    }
                    // if response includes an id
                    if (result._id || result.resultOfUpdate.nModified === 1 || result.resultOfUpdate.nModified === 0) {

                        if (result._id) {
                            let activityId = result._id
                            fetch(`/api/activitiesParticipants/new/${eventId}/${activityId}`, {
                                method: 'POST',
                                credentials: 'same-origin',
                                headers: { "Content-Type": 'application/json' },
                            })
                                .then(response => response.json())
                                .then(() => {
                                    ReactGA.event({ category: 'Activity', action: 'Created' })
                                    p.history.push('/event/' + eventId)
                                })
                        } else {
                            // direct to event page
                            p.history.push('/event/' + eventId)
                        }
                    }
                });
        } else {
            this.setState({ updateIsClicked: true })
        }
    }

    onInviteOthersClick() {
        let s = this.state
        let p = this.props
        let that = this
        let activityId = p.match.params.activityId
        let eventId = p.match.params.eventId

        let payload = {
            eventId,
            name: s.activityName,
            place: s.place,
            startDate: s.startDate.toDate(),
            finishDate: s.finishDate.toDate()
        }

        if (s.costPerson !== '') payload.costPerson = s.costPerson
        if (s.totalCost !== '') payload.totalCost = s.totalCost

        // relative path that will be added to tail of domain to make a call
        fetch(`/api/activities/` + (activityId ? activityId + '/update' : 'new'), {
            method: activityId ? 'PUT' : 'POST',
            // only authorized users, attach token/cookie
            credentials: "same-origin",
            headers: { "Content-Type": 'application/json' },
            // information/ payload being sent to server
            body: JSON.stringify(payload)
        }).then(response => (response.json()))
            // response from server
            .then((result) => {
                // if response has errors
                if (result.error) {
                    // update error state
                    that.setState({ error: result.error })
                }
                // if response includes an id
                if (result._id || result.resultOfUpdate.nModified === 1 || result.resultOfUpdate.nModified === 0) {

                    if (result._id) {
                        let activityId = result._id
                        fetch(`/api/activitiesParticipants/new/${eventId}/${activityId}`, {
                            method: 'POST',
                            credentials: 'same-origin',
                            headers: { "Content-Type": 'application/json' },
                        })
                            .then(response => response.json())
                            .then(() => {
                                p.history.push(`/event/${eventId}/${activityId}/invite`)
                            })
                    } else {
                        // direct to event page
                        p.history.push(`/event/${eventId}/${activityId}/invite`)
                    }
                }
            });
    }

    // Server GET request
    fetchPlaces(value) {
        // call to server
        fetch('/api/places/' + value, {
            method: 'GET',
            credentials: 'same-origin'
        })
            .then(response => response.json())
            .then(result => {
                // if result is not an error
                if (!result.error) {
                    // state change for placesList with results
                    this.setState({ placesList: result })
                }
            })
    }

    renderPlaceDropdown() {
        // placesList is an array, we loop through it
        return this.state.placesList.map((place, i) => (
            // return a list of address, 
            <div key={i}
                onClick={() => this.onSelectLocation(place.formatted_address)}>
                {place.formatted_address}
            </ div>
        ))
    }

    render() {
        let p = this.props
        let s = this.state
        let eventId = p.match.params.eventId
        let activityId = p.match.params.activityId

        return (
            <div className='CreateEditActivityComponent'>
                <div className="subContainer20">
                    <i className='mywanderLogo' />
                    <Initials right
                        name={p.user.name} />

                    <h1 className="sides20">{activityId ? 'Edit' : 'Create'} activity</h1>

                    <Input
                        placeholder='Enter activity name'
                        value={s.activityName}
                        onChange={this.onActivityInputsChange.bind(this, 'activityName')}
                        error={s.error && s.error.key === 'name' ? s.error.message : null} />

                    <PlacesAutocomplete
                        value={s.location}
                        onChange={this.onActivityInputsChange.bind(this, 'location')}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className='areaWrapper'>
                                <textarea
                                    {...getInputProps({
                                        placeholder: 'Location',
                                        className: 'locationInput'
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, i) => {
                                        const style = suggestion.active
                                            ? { backgroundColor: '#2d3954', cursor: 'pointer', color: '#ffffff' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' }

                                        return <div key={i} {...getSuggestionItemProps(suggestion, { style })}>
                                            {suggestion.description}
                                        </div>
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>

                    <i className='poweredByGoogle' />

                    <DateTimeElement
                        label='Start'
                        dateValue={s.startDate}
                        onDateChange={this.onActivityInputsChange.bind(this, 'startDate')}
                        dateError={s.error && s.error.key === 'startDate' ? s.error.message : null}
                        // timeValue={s.startTime}
                        // onTimeChange={this.onActivityInputsChange.bind(this, 'startTime')}
                        timeError={s.error && s.error.key === 'startTime' ? s.error.message : null} />

                    <DateTimeElement
                        class='finishInputs'
                        label='Finish'
                        dateValue={s.finishDate}
                        onDateChange={this.onActivityInputsChange.bind(this, 'finishDate')}
                        dateError={s.error && s.error.key === 'finishDate' ? s.error.message : null}
                        // timeValue={s.finishTime}
                        // onTimeChange={this.onActivityInputsChange.bind(this, 'finishTime')}
                        timeError={s.error && s.error.key === 'finishTime' ? s.error.message : null} />

                    <Input
                        class="costPerson"
                        type='currency'
                        placeholder='Cost per person ($)'
                        value={s.costPerson}
                        onChange={this.onActivityInputsChange.bind(this, 'costPerson')}
                        error={s.error && s.error.key === 'costPerson' ? s.error.message : null}
                    />

                    <Input
                        class='totalCost'
                        type='currency'
                        placeholder='Total activity cost ($)'
                        value={s.totalCost}
                        onChange={this.onActivityInputsChange.bind(this, 'totalCost')}
                        error={s.error && s.error.key === 'totalCost' ? s.error.message : null} />

                    {activityId ?
                        <button className='tiel'
                            onClick={() => { p.history.push(`/event/${eventId}/activity/${activityId}/confirmdeletion`) }}>
                            Delete activity
                        </button>
                        :
                        null
                    }

                    <div className='bottomPusher' />
                </div>

                <div className="footer">
                    <button className='left ghost circular' onClick={() => {
                        p.history.push('/event/' + eventId)
                    }}><i className="left blue arrow" /></button>

                    {!s.updateIsClicked ?
                        < button className="right" onClick={this.onAddUpdateActivityClick.bind(this)}>
                            <span>{activityId ? 'Update' : 'Add'}</span>
                        </button>
                        :
                        < button className="right"
                            onClick={this.onInviteOthersClick.bind(this)}>
                            <span>Invite others</span>
                        </button>
                    }

                </div>
            </div >
        )
    }
}


let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateEditActivity))


