// libraries
import React from 'react'
import moment from 'moment'
import ReactGA from 'react-ga';

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../../__redux/actions'
import { withRouter } from 'react-router-dom'

//components
import Input from '../../elements/Input'
import Initials from '../../elements/Initials'
import DateTimeElement from '../../elements/DateTime'
import PlacesAutocomplete from 'react-places-autocomplete';

class CreateEditEventComponent extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props);

        this.state = {
            eventName: '',
            location: '', // formated string like "3507 Palmilla Dr. San Jose, CA, USA"
            focused: '',
            placesList: [],
            startDate: moment().set('hour', 9).set('minute', 0), // moment object
            finishDate: moment().add(1, 'days').set('hour', 18).set('minute', 0), // moment object
            error: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true

        let eventId = this.props.match.params.id

        if (eventId) {
            fetch(`/api/events/${eventId}`, {
                method: 'GET',
                credentials: 'same-origin'
            }).then(response => response.json())
                .then(result => {
                    if (result.error && result.error.key === 'unauthorized') {

                        // Cookies.remove('token'); // TODO: bring back once stable
                    } else {
                        if (this._isMounted) {
                            result.eventName = result.name
                            delete result.name
                            result.startDate = moment(result.startDate)
                            result.finishDate = moment(result.finishDate)
                            this.setState(Object.assign(this.state, result))
                        }
                    }
                })
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    // checking for any value changes in inputs
    onEventInputsChange(type, value) {
        // if any value changes in any of the type state, it will update the state
        this.setState({ [type]: value })
    }

    onDeleteEventClick() {
        let p = this.props
        let eventId = p.match.params.id

        fetch(`/api/events/delete/${eventId}`, {
            method: 'DELETE',
            credentials: 'same-origin',
        })
            .then(response => response.json())
            .then(() => {
                p.history.push('/profile')
            })
    }

    onCreateUpdateEventClick() {
        let s = this.state
        let p = this.props
        let that = this
        let eventId = p.match.params.id

        let payload = {
            name: s.eventName,
            location: s.location,
            startDate: s.startDate.toDate(),
            finishDate: s.finishDate.toDate()
        }

        // relative path that will be added to tail of domain to make a call
        fetch(`/api/events/` + (eventId ? eventId + '/update' : 'new'), {
            method: eventId ? 'PUT' : 'POST',
            // only authorized users, attach token/cookie
            credentials: "same-origin",
            headers: { "Content-Type": 'application/json' },
            // information/ payload being sent to server
            body: JSON.stringify(payload)
        }).then(response => (response.json()))
            // response from server
            .then((result) => {
                // if response has errors
                if (result.error) {
                    // update error state
                    that.setState({ error: result.error })
                }
                // if response includes an id
                if (result._id || result.resultOfUpdate.nModified === 1 || result.resultOfUpdate.nModified === 0) {
                    if (result._id) {
                        let eventId = result._id
                        fetch(`/api/eventsParticipants/new/${eventId}`, {
                            method: 'POST',
                            credentials: 'same-origin',
                            headers: { "Content-Type": 'application/json' },
                        })
                            .then(response => response.json())
                            .then(() => {
                                ReactGA.event({ category: 'Event', action: 'Created' });
                                p.history.push('/event/' + (eventId ? eventId : result._id))
                            })
                    } else {
                        // direct to event page
                        p.history.push('/event/' + (eventId ? eventId : result._id))
                    }
                }
            });
    }

    render() {
        let p = this.props
        let s = this.state
        let eventId = p.match.params.id

        return (
            <div className='CreateEditEventComponent'>

                <div className="subContainer20">

                    <i className='mywanderLogo' />

                    <Initials right
                        name={p.user.name} />

                    <h1 className="sides20">{p.match.params.id ? 'Edit event' : 'Create event'}</h1>

                    <Input
                        placeholder='Enter event name'
                        value={s.eventName}
                        onChange={this.onEventInputsChange.bind(this, 'eventName')}
                        error={s.error && s.error.key === 'name' ? s.error.message : null} />

                    <PlacesAutocomplete
                        value={s.location}
                        onChange={this.onEventInputsChange.bind(this, 'location')}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className='areaWrapper'>
                                <textarea
                                    {...getInputProps({
                                        placeholder: 'Location',
                                        className: 'locationInput'
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, i) => {
                                        const style = suggestion.active
                                            ? { backgroundColor: '#2d3954', cursor: 'pointer', color: '#ffffff' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' }

                                        return <div key={i} {...getSuggestionItemProps(suggestion, { style })}>
                                            {suggestion.description}
                                        </div>
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>

                    <i className='poweredByGoogle' />

                    <DateTimeElement
                        label='Start'
                        dateValue={s.startDate}
                        onDateChange={this.onEventInputsChange.bind(this, 'startDate')}
                        dateError={s.error && s.error.key === 'startDate' ? s.error.message : null}
                        timeError={s.error && s.error.key === 'startTime' ? s.error.message : null} />


                    <DateTimeElement
                        class='finishInputs'
                        label='finish'
                        dateValue={s.finishDate}
                        onDateChange={this.onEventInputsChange.bind(this, 'finishDate')}
                        dateError={s.error && s.error.key === 'finishDate' ? s.error.message : null}
                        timeError={s.error && s.error.key === 'finishTime' ? s.error.message : null} />

                    {p.match.params.id ?
                        <button
                            className='tiel'
                            onClick={() => { p.history.push(`/event/${eventId}/confirmdeletion`) }}>
                            Delete event
                        </button>
                        :
                        null
                    }

                    <div className='bottomPusher' />
                </div>

                <div className="footer">
                    <button className='left ghost circular' onClick={() => { p.history.push('/profile') }}><i className="left blue arrow" /></button>

                    <button className="right" onClick={this.onCreateUpdateEventClick.bind(this)}><span>{p.match.params.id ? 'Update' : 'Create'} event</span></button>
                </div>

            </div >
        )
    }
}

let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateEditEventComponent))