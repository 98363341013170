//libraries
import React from 'react'

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../../../__redux/actions'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'

// components
import SelectContact from '../../../elements/SelectContact'


class ActivityContacts extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            activityParticipants: [],
            selectedContactsIds: []
        }
        this.eventId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).eventId
        this.activityId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).activityId
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true
        let p = this.props
        let that = this

        fetch(`/api/activitiesParticipants/participantList/${this.activityId}`, {
            method: 'GET',
            credentials: "same-origin",
        })
            .then(response => (response.json()))
            .then((activityParticipants) => {
                if (this._isMounted && activityParticipants.error) {
                    that.setState({ error: activityParticipants.error })
                } else if (this._isMounted) {
                    this.setState({
                        activityParticipants: activityParticipants.participants
                            .filter(prt => prt.participantUserId !== p.user._id)
                    })
                }
            })
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    onParticipantClick(participantId) {
        let s = this.state
        let selectedContactsIds = s.selectedContactsIds.includes(participantId) ?
            s.selectedContactsIds.filter(id => id !== participantId)
            :
            s.selectedContactsIds.concat(participantId)
        this.setState({ selectedContactsIds })
    }

    onMessageClick() {
        let s = this.state
        let p = this.props

        if (s.selectedContactsIds.length < 1) return null;

        let payload = {
            recipients: s.selectedContactsIds,
            activityId: this.activityId
        }

        if (s.selectedContactsIds.length === 1) {
            p.history.push(`/chat?` +
                `eventId=${this.eventId}&` +
                `activityId=${this.activityId}&` +
                `recipientId=${s.selectedContactsIds[0]}&` +
                `from=activity`)
            return null
        }

        fetch('/api/messages/new/group', {
            method: 'POST',
            credentials: 'same-origin',
            headers: { "Content-Type": 'application/json' },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then((result) => {
                p.history.push(`/chat?` +
                    `eventId=${this.eventId}&` +
                    `activityId=${this.activityId}&` +
                    `groupId=${result.groupId}&` +
                    `from=activity`)
            })
    }

    render() {
        let s = this.state
        let p = this.props

        return (
            <div className='ActivityContactsComponent'>
                <SelectContact
                    title='Select contact'
                    contacts={s.activityParticipants}
                    onContactClick={this.onParticipantClick.bind(this)}
                    selectedContacts={s.selectedContactsIds}
                    onBackClick={() => p.history.push(`/event/${this.eventId}/activity/${this.activityId}`)}
                    rightButton={{
                        title: 'Message',
                        click: this.onMessageClick.bind(this),
                        additionalClass: s.selectedContactsIds.length < 1 ? ' disabled' : ''
                    }}
                />
            </div >
        )
    }
}


let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityContacts))