//libraries
import React from 'react'
import ReactGA from 'react-ga';

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../__redux/actions'
import { withRouter } from 'react-router-dom'

//components
import Input from '../elements/Input'
// import Loader from 'react-loader-spinner'


class SignInComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: null,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    // checking for any value changes in inputs
    onSignInInputsChange(type, value) {
        // if any value changes in any of the type state, it will update the state
        this.setState({ [type]: value })
    }

    onInputKeyPress(e) {
        if (e.key === 'Enter') this.submitForm()
    }

    submitForm() {
        let p = this.props
        let that = this
        p.actions.signin({
            email: that.state.email,
            password: that.state.password
        }, (res) => {
            if (res.error) {
                that.setState(res)
            } else {
                p.history.push('/profile')
            }
        })
    }


    render() {
        let s = this.state
        return (
            <div className='SignInComponent'>

                <div className="subContainer20">

                    <i className='mywanderLogo' />

                    <h1 className="sides20">Sign In</h1>
                    <form>

                        <Input
                            placeholder='Enter your email'
                            value={this.state.email}
                            onKeyPress={this.onInputKeyPress.bind(this)}
                            onChange={this.onSignInInputsChange.bind(this, 'email')}
                            error={s.error && s.error.key === 'email' ? s.error.message : null} />

                        <Input
                            placeholder='Enter your password'
                            type='password'
                            value={this.state.password}
                            onKeyPress={this.onInputKeyPress.bind(this)}
                            onChange={this.onSignInInputsChange.bind(this, 'password')}
                            error={s.error && s.error.key === 'password' ? s.error.message : null} />


                        <a onClick={() => { this.props.history.push('/recovery') }}>Forgot Password</a>
                    </form>
                </div>

                <div className="footer">
                    <a className="left bold"
                        onClick={() => {
                            this.props.history.push('/signup'),
                            ReactGA.event({ category: 'Sign up', action: 'Open sign up' })
                        }}>
                        Sign Up
                    </a>
                    <button type="button" className="right circular" onClick={this.submitForm.bind(this)}><i className="right white arrow" /></button>
                </div>

            </div>
        )
    }
}

let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInComponent))
