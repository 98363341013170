//libraries
import React from 'react'

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../../__redux/actions'
import { withRouter } from 'react-router-dom'

// components
import DeleteAlert from '../../elements/DeleteAlert'

class ConfirmEventDeletionComponent extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            event: null,
            activitiesByUser: [],
            ParticipantsIdsOfActivitiesCreatedByUser: [],
            activitiesByParticipants: [],
            creatorsOfActivities: [],
            selectedCreator: []
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true
        let p = this.props
        let that = this
        let eventId = p.match.params.eventId

        fetch(`/api/events/${eventId}/corresponding`, {
            method: 'GET',
            // attach token/cookie
            credentials: "same-origin",
        }).then(response => (response.json()))
            .then((result) => {
                if (result.error) {
                    that.setState({ error: result.error })
                }
                if (this._isMounted) {
                    // when event is received with id, update state of event with results
                    this.setState({
                        event: result.eventResults,
                        activitiesByUser: result.activitiesByUser,
                        ParticipantsIdsOfActivitiesCreatedByUser:
                            result.participantsOfActivitiesCreatedByUser,
                        activitiesByParticipants: result.activitiesByParticipants,
                        creatorsOfActivities: result.creatorsOfActivities
                    })
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    onCreatorClick(creatorId) {
        let s = this.state
        if (s.selectedCreator.includes(creatorId)) {
            this.setState({
                selectedCreator: s.selectedCreator.filter(id => id !== creatorId)
            })
        } else if (s.selectedCreator.length === 0) {
            this.setState({
                selectedCreator: s.selectedCreator.concat(creatorId)
            })
        }
    }

    onReassignClick() {
        let p = this.props
        let eventId = p.match.params.eventId
        let payload = {
            id: this.state.selectedCreator
        }
        fetch(`/api/events/${eventId}/reassign/`, {
            method: 'PUT',
            credentials: 'same-origin',
            headers: { "Content-Type": 'application/json' },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(() => {
                p.history.push('/profile')
            })
    }

    onDeleteClick() {
        let p = this.props
        let eventId = p.match.params.eventId

        fetch(`/api/events/${eventId}/delete/`, {
            method: 'DELETE',
            credentials: 'same-origin',
        })
            .then(response => response.json())
            .then(() => {
                p.history.push('/profile')
            })
    }

    renderMessage2(emptyEvent, createdByUser) {
        let s = this.state
        let lengthOfActivitiesByUser = s.activitiesByUser.length
        let out
        if (!createdByUser && emptyEvent) {
            out = ''
        } else if (createdByUser && !emptyEvent) {
            out = `You have ${lengthOfActivitiesByUser} activity(ies) in this event, please refund all participants.`
        } else {
            out = 'There are activities created by other participants of this event, please ask participants to delete activity or assign someone from the list of activity creators below to take over the event. Activities created by you will not be reassigned, please delete and refund participants before reassigning.'
        }
        return out
    }

    renderMessage1(emptyEvent, createdByUser) {
        let s = this.state
        let out

        if (s.event && emptyEvent && !createdByUser || s.event && !emptyEvent && createdByUser) {
            out = `Do you really want to delete ${s.event.name} event?`
        } else if (s.event && !emptyEvent && !createdByUser) {
            out = ` ${s.event.name} event cannot be deleted`
        }
        return out
    }

    render() {
        let p = this.props
        let s = this.state
        let eventId = p.match.params.eventId
        let createdByUser = s.activitiesByUser.length > 0 && s.ParticipantsIdsOfActivitiesCreatedByUser.length >= 0
            && s.activitiesByParticipants.length === 0
        let emptyEvent = s.activitiesByUser.length === 0 && s.ParticipantsIdsOfActivitiesCreatedByUser.length === 0
            && s.activitiesByParticipants.length === 0

        return (
            <div className='DeleteAlertElement'>

                < DeleteAlert
                    message1={this.renderMessage1(emptyEvent, createdByUser)}
                    refundAlert={this.renderMessage2(emptyEvent, createdByUser)}
                    activityCreators={s.creatorsOfActivities}
                    onCreatorClick={this.onCreatorClick.bind(this)}
                    selectedCreator={s.selectedCreator}
                    onCancelClick={() => p.history.push(`/event/${eventId}`)}
                    rightButton={{
                        title: s.event && emptyEvent && !createdByUser ||
                            s.event && !emptyEvent && createdByUser ?
                            'Delete' : 'Reassign',
                        click: emptyEvent && !createdByUser ||
                            s.event && !emptyEvent && createdByUser ?
                            this.onDeleteClick.bind(this) : this.onReassignClick.bind(this),
                        additionalClass:
                            !emptyEvent && !createdByUser && s.selectedCreator.length < 1 ?
                                ' disabled'
                                : ''
                    }}
                />
            </div >
        )
    }
}

let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmEventDeletionComponent))