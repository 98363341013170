// libraries
import React from 'react'

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../../__redux/actions'
import { withRouter } from 'react-router-dom'


// components
import Initials from '../../elements/Initials'

class DeleteAlertElement extends React.Component {
    render() {
        let p = this.props

        return (
            <div className='DeleteAlertElement'>
                <div className="subContainer20">
                    <i className='mywanderLogo' />
                    <Initials right
                        name={p.user.name} />
                    <h1 className='sides20'>{p.title}</h1>

                    <div className='alertMessage'>
                        <h1>Are you sure?</h1>
                        <h4 className='message1'>
                            {p.message1}
                        </h4>
                        <span className='message2'>
                            {p.refundAlert}
                        </span>
                    </div>
                    {p.activityCreators.length > 0 ?
                        <ul className='creators'>
                            <div className='title'>Activity creators:</div>
                            {p.activityCreators.map((creator, i) =>
                                <li key={i}
                                    onClick={p.onCreatorClick.bind(this, creator.id)}
                                    className={'creator'
                                        + (p.selectedCreator.includes(creator.id) ? ' active' : '')} >
                                    <h4 className='thin name'>
                                        {creator.name}
                                    </h4>
                                </li>
                            )}
                        </ul>
                        : null
                    }

                    <div className='bottomPusher' />
                </div>

                <div className="footer">
                    <button
                        className='left ghost'
                        onClick={p.onCancelClick.bind(this)}>
                        <span>Cancel</span>
                    </button>

                    <button
                        className={"right" + p.rightButton.additionalClass}
                        onClick={p.rightButton.click}>
                        <span>{p.rightButton.title}</span>
                    </button>
                </div>
            </div>
        )
    }
}

let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteAlertElement))