//libraries
import React from 'react'
import ReactGA from 'react-ga';
// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../__redux/actions'
import { withRouter } from 'react-router-dom'

//components
import Input from '../elements/Input'
import Initials from '../elements/Initials'


class InviteOthersComponent extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            error: null,
            contactsList: [],
            invitedContacts: []
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this._isMounted = true
        let eventId = this.props.match.params.eventId

        // call to server to fetch contacts by event id
        fetch(`/api/events/${eventId}/contacts`, {
            method: 'GET',
            credentials: 'same-origin'
        }).then(response => response.json())
            .then(contactsIdsForEventFromDb => {
                //concatenating array results from database and global state array
                let allSelectedContacts = contactsIdsForEventFromDb
                    .concat(this.props.temp.selectedContacts)

                // call to server to fetch list of all contacts
                fetch(`/api/contacts/list`, {
                    method: 'GET',
                    credentials: 'same-origin'
                }).then(response => response.json())
                    .then(contactsFromDB => {
                        // once list of contacts is received,

                        // iterate through list to filter out only contact ids that match
                        // with contact ids from allSelectedContacts
                        let filtered = contactsFromDB
                            .filter(cfdb => (
                                allSelectedContacts.includes(cfdb._id)
                            ))

                        if (this._isMounted) {
                            this.setState({
                                contactsList: filtered,
                                invitedContacts: contactsIdsForEventFromDb
                            })
                        }
                    })
                // this.setState({ contactsList: invitesFromDb })
            })
    }


    componentWillUnmount() {
        this._isMounted = false
    }

    onInviteInputsChange(type, value) {
        // if any value changes in any of the type state, it will update the state
        this.setState({ [type]: value })
    }

    onAddForInviteClick() {
        let s = this.state
        let p = this.props
        let that = this
        let eventId = p.match.params.eventId

        let payload = {
            eventId,
            name: s.name,
            phone: s.phone,
            email: s.email
        }

        // relative path that will be added to tail of domain to make a call
        fetch('/api/contacts/new', {
            // to create new contact
            method: 'POST',
            // only authorized users, attach token/cookie
            credentials: "same-origin",
            headers: { "Content-Type": 'application/json' },
            // information/ payload being sent to server
            body: JSON.stringify(payload)
        })
            .then(response => (response.json()))
            // response from server
            .then((result) => {
                // if response has errors
                if (result.error) {
                    // update error state
                    that.setState({ error: result.error })
                }
                // if response includes an id
                if (result._id) {
                    that.setState({
                        contactsList: s.contactsList.concat([result])
                    })

                    // if there are any p.temp.selectedContacts place in combinedContacts else place empty []
                    let combinedContacts = p.temp.selectedContacts ? p.temp.selectedContacts : []
                    //concatenate combinedContacts and result id in combinedContacts
                    combinedContacts = combinedContacts.concat([result._id])

                    // updating global state of setSelectedContacts
                    p.actions.setSelectedContacts(combinedContacts)
                }

            });
    }


    onXClick(contactId) {
        let p = this.props

        // filter each selectedContact id with contactId received and set global state of setSelectedContacts with results
        p.actions.setSelectedContacts(p.temp.selectedContacts.filter(sContact => (
            sContact !== contactId
        )))
    }


    onInviteButtonClick() {
        let p = this.props
        let eventId = p.match.params.eventId

        //
        let payload = p.temp.selectedContacts.filter(sContact => (
            // check if selectedContact is not included in invitedContacts
            !this.state.invitedContacts.includes(sContact)
        ))

        // call to create invites collection with provided payload
        fetch(`/api/invites/newMany/${eventId}`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: { "Content-Type": 'application/json' },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            // .then( invitesResult => {
            .then(() => {
                ReactGA.event({ category: 'Invite Others', action: 'Invited Others' })
                p.actions.cleanSelectedContacts()
                p.history.push(`/event/${eventId}`)
            })
    }

    onLeftButtonClick() {
        let p = this.props
        let eventId = p.match.params.eventId

        // clear list of selected contacts
        p.actions.cleanSelectedContacts()
        // redirect to event page
        this.props.history.push('/event/' + eventId)
    }

    render() {
        let p = this.props
        let s = this.state
        let eventId = p.match.params.eventId

        return (
            <div className='InviteOthersComponent'>
                <div className="subContainer20">
                    <Initials right
                        name={p.user.name} />
                    <i className='mywanderLogo' />
                    <h1>Invite Others</h1>
                    <i className='phoneBook'
                        onClick={() => { p.history.push(`/event/${eventId}/invite/contacts`) }}
                    />

                    {s.contactsList.length > 0 ?
                        <ul className='invitees'>
                            {s.contactsList.map((contact, index) => (
                                <li key={index} className='contact'>
                                    <span className='name'>
                                        {/* check if this contactId has been sent an invite, (if it exists in invitedContacts) */}
                                        {s.invitedContacts.includes(contact._id) ?
                                            // if it exists place icon, else nothing
                                            <i className="checkmark" /> : null
                                        }
                                        {contact.name}
                                    </span>
                                    {/* check if this contactId exists in invitedContacts */}
                                    {s.invitedContacts.includes(contact._id) ? null
                                        // if it doesn't exist place x icon
                                        : <div className='removeWrapper' onClick={this.onXClick.bind(this, contact._id)}>
                                            <i className='remove' />
                                        </div>}
                                </li>
                            ))}

                        </ul> :
                        null
                    }

                    <Input
                        placeholder='Name'
                        value={s.name}
                        onChange={this.onInviteInputsChange.bind(this, 'name')}
                        error={s.error && s.error.key === 'name' ? s.error.message : null}
                    />
                    <Input
                        type='tel'
                        placeholder='Phone'
                        value={s.phone}
                        onChange={this.onInviteInputsChange.bind(this, 'phone')}
                        error={s.error && s.error.key === 'phone' ? s.error.message : null}
                    />
                    <Input
                        placeholder='Email'
                        value={s.email}
                        onChange={this.onInviteInputsChange.bind(this, 'email')}
                        error={s.error && s.error.key === 'email' ? s.error.message : null}
                    />

                    <button className={'tiel'}
                        onClick={this.onAddForInviteClick.bind(this)} >Add for invite</button>

                    <div className='bottomPusher' />
                </div>
                <div className="footer">
                    <button
                        className='left ghost circular'
                        onClick={this.onLeftButtonClick.bind(this)}>
                        <i className="left blue arrow" />
                    </button>

                    <button
                        onClick={this.onInviteButtonClick.bind(this)}
                        className={'right' + (!p.temp.selectedContacts ? ' disabled' : '')}><span>Invite</span></button>
                </div>
            </div>
        )
    }
}

let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InviteOthersComponent))
