//libraries
import React from 'react'

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../../__redux/actions'
import { withRouter } from 'react-router-dom'

// components
import SelectContact from './../../elements/SelectContact'

class EventParticipants extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            eventParticipants: [],
            selectedContactsIds: []
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this._isMounted = true
        let p = this.props
        let eventId = p.match.params.eventId
        let that = this

        fetch(`/api/eventsParticipants/list/${eventId}`, {
            method: 'GET',
            credentials: "same-origin",
        })
            .then(response => (response.json()))
            .then((eventParticipants) => {
                if (this._isMounted && eventParticipants.error) {
                    that.setState({ error: eventParticipants.error })
                } else if (this._isMounted) {
                    this.setState({ eventParticipants: eventParticipants.filter(prt => prt.participantUserId !== p.user._id) })
                }
            })
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    onParticipantClick(participantId) {
        let s = this.state
        let selectedContactsIds = s.selectedContactsIds.includes(participantId) ?
            s.selectedContactsIds.filter(id => id !== participantId)
            :
            s.selectedContactsIds.concat(participantId)

        this.setState({ selectedContactsIds })
    }

    onInviteClick() {
        let s = this.state
        let p = this.props
        let eventId = p.match.params.eventId
        let activityId = p.match.params.activityId
        let payload = s.selectedContactsIds

        // call to update activities collection with provided payload
        fetch(`/api/activities/invites/${activityId}`, {
            method: 'PUT',
            credentials: 'same-origin',
            headers: { "Content-Type": 'application/json' },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(() => {
                p.history.push(`/event/${eventId}`)
            })
    }

    render() {
        let s = this.state
        let p = this.props
        let eventId = p.match.params.eventId

        return (
            <div>
                <SelectContact
                    title='Select contact'
                    contacts={s.eventParticipants}
                    onContactClick={this.onParticipantClick.bind(this)}
                    selectedContacts={s.selectedContactsIds}
                    onBackClick={() => p.history.push(`/event/${eventId}`)}
                    rightButton={{
                        title: 'Invite',
                        click: this.onInviteClick.bind(this),
                        additionalClass: s.selectedContactsIds.length < 1 ? ' disabled' : ''
                    }}
                />
            </div>
        )
    }
}


let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventParticipants))