//libraries
import React from 'react'
import _ from 'lodash'
import Cookies from 'js-cookie'
import ReactGA from 'react-ga';

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../__redux/actions'
import { withRouter } from 'react-router-dom'

//components
import Input from '../elements/Input'
import Initials from '../elements/Initials'

class ProfileComponent extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            eventsList: [],
            eventsImInvitedTo: [],
            edit: false,
            password: '',
            newPassword: '',
            unReadMessages: [],
            loader: false,
            error: null,

            // for edit mode
            eName: '',
            ePhone: '',
            eEmail: '',
        }
    }

    UNSAFE_componentWillMount() {
        window.scrollTo(0, 0)
        this._isMounted = true

        fetch('/api/events/list', {
            method: 'GET',
            credentials: 'same-origin'
        }).then(response => response.json())
            .then(eventsListFromDB => {

                fetch('/api/contacts/eventsImInvitedTo', {
                    method: 'GET',
                    credentials: 'same-origin'
                }).then(response => response.json())
                    .then(listOfEventsImInvitedToFromDB => {
                        if (this._isMounted) {
                            this.setState({
                                eventsList: eventsListFromDB,
                                eventsImInvitedTo: listOfEventsImInvitedToFromDB
                            })
                        }
                    }) // /api/contacts/eventsImInvitedTo
            })// /api/events/list

        fetch(`/api/messages/conversations`, {
            method: 'GET',
            // attach token/cookie
            credentials: "same-origin",
        }).then(response => (response.json()))
            .then((result) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                if (this._isMounted) {
                    let unRead = result.filter(message =>
                        message.unreadMessagesCount > 0
                    )
                    this.setState({ unReadMessages: unRead })
                }
            })
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    createPayload() {
        let pu = this.props.user
        let s = this.state
        let out = {}

        if (s.eName !== pu.name) out.name = s.eName
        if (s.ePhone !== pu.phone) out.phone = s.ePhone
        if (s.eEmail !== pu.email) out.email = s.eEmail

        if ((s.password !== '' && s.newPassword !== '') && (s.password !== s.newPassword)) {
            out.password = s.password
            out.newPassword = s.newPassword
        }
        return out
    }

    // when log out link event triggered, it will remove/delete cookie and send user back to sign in page.
    onLogoutClick() {
        Cookies.remove('token');
        this.props.history.push('/signin')
    }

    onProfileChange(type, value) {
        this.setState({ [type]: value })
    }

    // when edit icon event triggered, it will set edit page state to true and copy user props received from main index.js to profile state eEmail, ePhone and eEmail
    onEditPenClick() {
        this.setState({ edit: true })
        let pu = this.props.user
        this.setState({
            eName: pu.name,
            ePhone: pu.phone,
            eEmail: pu.email
        })
    }

    // cancels all changes in edit page and sets it to false, user is send back to profile
    onCancelClick() {
        this.setState({
            edit: false,
            password: '',
            newPassword: ''
        })
    }

    // onSaveClick function will be invoked when Save button is clicked in edit page.
    // validates and saves user credentials in database and direct back to profile page
    onSaveClick() {
        let p = this.props
        let that = this

        // updated information by user placed in variable
        let payload = this.createPayload()
        // if object is empty (user made no changes) no call will be made
        if (_.isEmpty(payload)) return null

        // first let's set loader
        this.setState({ loader: true })

        // trigger updateUser in actions.js,
        p.actions.updateUser(payload, (res) => {
            // if response is error
            if (res.error) {
                // state and loader state will be updated
                that.setState({ error: res.error, loader: false })
                // if no errors
            } else {
                // loader and edit state will be updated
                that.setState({ loader: false, edit: false })
                // getUser action/ function will be invoked and will GET updated user information
                p.actions.getUser()
            }
        })
    } // onSaveClick

    // renderInfo function will be invoked when edit state is false
    renderInfo() {
        let p = this.props
        return (
            <div className='info'>
                <h2 className='name'>{p.user && p.user.name}</h2>
                <i className='editPen' onClick={this.onEditPenClick.bind(this)} />
            </div>
        )
    } //renderInfo

    // renderEdit function will be invoked when edit state is true
    renderEdit() {
        let s = this.state
        return (
            <div className='edit'>

                <Input dark
                    placeholder='Your name'
                    value={this.state.eName}
                    onChange={this.onProfileChange.bind(this, 'eName')}
                    error={s.error && s.error.key === 'name' ? s.error.message :
                        null}
                />

                <Input dark
                    type='tel'
                    placeholder='Your phone'
                    value={this.state.ePhone}
                    onChange={this.onProfileChange.bind(this, 'ePhone')}
                    error={s.error && s.error.key === 'phone' ? s.error.message : null}
                />

                <Input dark
                    placeholder='Your email'
                    value={this.state.eEmail}
                    onChange={this.onProfileChange.bind(this, 'eEmail')}
                    error={s.error && s.error.key === 'email' ? s.error.message : null}
                />

                <h2 className='passwordHeader'>Change password</h2>

                <Input dark
                    placeholder='Enter your password'
                    value={this.state.password}
                    onChange={this.onProfileChange.bind(this, 'password')}
                    error={s.error && s.error.key === 'password' ? s.error.message : null}
                />

                <Input dark
                    placeholder='Enter new password'
                    value={this.state.newPassword}
                    onChange={this.onProfileChange.bind(this, 'newPassword')}
                    error={s.error && s.error.key === 'newPassword' ? s.error.message : null}
                />

            </div>
        )
    } // renderEdit



    render() {
        let s = this.state
        let p = this.props
        let allEvents = s.eventsList.concat(s.eventsImInvitedTo)


        return (
            <div className='ProfileComponent'>

                <div className={'header' + (s.edit ? ' edit' : '')}>
                    <i className='mywanderLogo white' />
                    <span className='logout' onClick={this.onLogoutClick.bind(this)}>Log out</span>

                    <Initials
                        name={this.state.edit ? this.state.eName : p.user.name} />

                    <i className='questionmark white'
                        onClick={() => { p.history.push(`/feedback?` + `from=profile`) }} />

                    {s.edit ? <h2 className='editPhotoLink'>Edit profile</h2> : null}
                    {s.edit ? this.renderEdit() : this.renderInfo()}
                </div>

                {!s.edit ?
                    <div className='subContainer20'>
                        <h2 className='myEventsTitle'>My events</h2>

                        {/* if events exist, loop through array and display only name */}
                        {allEvents.length > 0 ?
                            <ul className='events'>
                                {allEvents.map((event, index) => (

                                    <li key={index}>

                                        <span
                                            className='eventName'
                                            onClick={() => {
                                                p.history.push(`/event/${event._id}`)
                                            }}>{event.name}
                                        </span>

                                        {event.creator === p.user._id ?
                                            <div className='editWrapper'
                                                onClick={() => {
                                                    p.history.push(`/event/${event._id}/edit`)
                                                }}>
                                                <i className='editPenBlue' />
                                            </div> :
                                            <div className='editWrapper'>
                                                <Initials creatorInitials
                                                    name={event.creatorName} />
                                            </div>}

                                    </li>
                                ))}
                            </ul>
                            :
                            <div className='noEvents'>You have no events</div>
                        }

                        <div className='bottomPusher' />

                    </div> : null}



                {!s.edit ?
                    <div className="footer">
                        <button className='left ghost message' onClick={() => { this.props.history.push('/messages') }}>
                            {s.unReadMessages && s.unReadMessages.length > 0 ? <span className="dot" /> : null}
                            <span>My messages</span>
                        </button>

                        <button className='right'
                            onClick={() => {
                                this.props.history.push('event/new'),
                                ReactGA.event({ category: 'Event', action: 'Create New' })
                            }} >
                            <span className='newEvent'>New event</span>
                        </button>
                    </div> :
                    <div className='footer'>
                        <button className='left ghost' onClick={this.onCancelClick.bind(this)}><span>Cancel</span></button>
                        <button className='right blue' onClick={this.onSaveClick.bind(this)}><span>{s.loader ? 'saving...' : 'Save'}</span></button>
                    </div>}

            </div>
        )
    }
} //ProfileComponent


let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileComponent))
