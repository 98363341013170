// libraries
import React from 'react'
import NumberFormat from 'react-number-format'
import DatePicker from "react-datepicker";
import moment from 'moment'

class InputElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focused: ''
        }
    }

    onChange(e) {
        this.props.onChange(e.target.value)
    }

    onInputFocus(ee) {
        let top = 0

        const calcTop = (el) => {
            top += el.offsetTop
            if (el.offsetParent) calcTop(el.offsetParent)
        }
        ee.persist()
        let e = ee

        setTimeout(() => {
            calcTop(e.target)

            document
                .getElementsByClassName("ContainerComponent")[0]
                .scroll({
                    top: top - 90,
                    behavior: 'smooth'
                })
        }, 500)
    }

    //depending on type of input, this helper will return the input
    inputRender() {
        let p = this.props

        if (p.type === 'tel') return (
            <NumberFormat
                // onchange event occurs when the value of an element has been changed
                onChange={this.onChange.bind(this)}
                className='input' format="+1 (###) ###-####" mask="_"
                // the value attribute specifies the initial value of the element
                value={p.value}
                // onfocus event occurs when an element gets focus
                onFocus={(e) => {
                    this.onInputFocus(e)
                    this.setState({ focused: true })
                }}
                // onblur event occurs when an object loses focus
                onBlur={() => { this.setState({ focused: false }) }}
            />
        )

        if (p.type === 'date') {
            return (
                <DatePicker
                    onChange={(date) => {
                        // the date received will be changed to moment object
                        this.props.onChange(moment(date))
                    }}
                    // toDate will transform a moment object into a date object
                    //Tue Nov 04 2014 14:04:01 GMT-0600 (CST)
                    selected={p.value.toDate()}
                />
            )
        }

        if (p.type === 'time') {
            return (
                <div className='input time'>
                    < NumberFormat
                        format="##:##" mask="_"
                        // specifies a short hint that describes the expected value of an input field
                        placeholder={this.state.focused ? 'hh/mm' : null}
                        // the value attribute specifies the initial value of the element
                        // moment object time formatted
                        value={p.value.format('hh:mm')}
                        // onfocus event occurs when an element gets focus
                        onFocus={(e) => {
                            this.onInputFocus(e)
                            this.setState({ focused: true })
                        }}
                        // onblur event occurs when an object loses focus
                        onBlur={(e) => {
                            // value in input field
                            let val = e.target.value
                            // time string changed to array and converted to integers
                            let time = val.split(':').map(v => parseInt(v))
                            // value of hours not exceed 12 and minutes not exceed 59
                            if (time[0] > 12) time[0] = 12
                            if (time[1] > 59) time[1] = 59
                            // AM/PM format
                            let ampm = p.value.format('A')
                            // set the date in moment object with date received
                            let newDate = p.value.set({
                                hour: ampm === 'PM' ? time[0] + 12 : time[0],
                                minute: time[1]
                            })
                            this.props.onChange(newDate)
                            this.setState({ focused: false })
                        }
                        }
                    />
                    <select className='amPmDropdown'
                        // moment object formats to  capital AM/PM 
                        value={p.value.format('A')}
                        onChange={(e) => {
                            // value received
                            let val = e.target.value
                            let newDate
                            // if the value from moment object is AM and the value entered by user id PM, add 12 hours to time in moment object
                            if (p.value.format('A') === 'AM' && val === 'PM') newDate = p.value.add(12, 'hours')
                            // if the value from moment object is AM and the value entered by user id AM, subtract 12 hours to time in moment object
                            if (p.value.format('A') === 'PM' && val === 'AM') newDate = p.value.subtract(12, 'hours')
                            this.props.onChange(newDate)
                        }}>
                        {/* dropdown options */}
                        <option value='AM'>AM</option>
                        <option value='PM'>PM</option>
                    </select >
                </div>
            )
        }

        if (p.type === 'currency') return (
            <NumberFormat
                thousandSeparator={true}
                prefix='$'
                // onchange event occurs when the value of an element has been changed
                onChange={this.onChange.bind(this)}
                className={'input'}
                // specifies a short hint that describes the expected value of an input field
                placeholder={this.state.focused ? "$1" : null}
                // the value attribute specifies the initial value of the element
                value={p.value}
                // onfocus event occurs when an element gets focus
                onFocus={(e) => {
                    this.onInputFocus(e)
                    this.setState({ focused: true })
                }}
                // onblur event occurs when an object loses focus
                onBlur={() => { this.setState({ focused: false }) }}
            />
        )

        if (p.type === 'area') return (
            <textarea
                value={p.value}
                rows="2"
                id={p.id || null}
                className={'input'}
                onChange={this.onChange.bind(this)}
                onFocus={(e) => {
                    this.onInputFocus(e)
                    this.setState({ focused: true })
                }}
                onBlur={() => {
                    this.setState({ focused: false })
                    p.onBlur && p.onBlur(this)
                }} />
        )

        return (
            <input
                id={p.id || null}
                className={'input'}
                type={p.type || 'text'}
                // onchange event occurs when the value of an element has been changed
                onChange={this.onChange.bind(this)}
                onKeyPress={this.props.onKeyPress ? this.props.onKeyPress.bind(this) : null}
                // the value attribute specifies the initial value of the element
                value={p.value}
                // onfocus event occurs when an element gets focus
                onFocus={(e) => {
                    this.onInputFocus(e)
                    this.setState({ focused: true })
                }}
                // onblur event occurs when an object loses focus
                onBlur={() => {
                    this.setState({ focused: false })
                    p.onBlur && p.onBlur(this)
                }}
            />
        )
    }

    render() {
        let p = this.props
        let s = this.state

        return (

            <div className=
                {'InputElement' +
                    (p.dark ? ' dark' : '') +
                    (p.error ? ' error' : '') +
                    (p.icon ? ' withIcon' : '') +
                    (s.focused ? ' focused' : '') +
                    (p.class ? ' ' + p.class : '')}>

                {p.placeholder ?
                    <span className={'placeholder' +
                        (s.focused ||
                            p.value.length > 0 ||
                            moment.isMoment(p.value) ? ' focused' : '')
                    }>{p.placeholder}</span>
                    : null
                }

                {p.icon ? <i className={p.icon} /> : null}

                {this.inputRender()}

                {p.error ? <span className='errorMessage'>{p.error}</span> : null}

            </div>
        )
    }
}
export default InputElement

