//libraries
import React from 'react'
import helpers from '../../helpers'
import moment from 'moment'

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../../__redux/actions'
import { withRouter } from 'react-router-dom'

// components
import Initials from '../../elements/Initials'

class ActivityComponent extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            activity: null,
            activityParticipants: [],
            error: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true
        let p = this.props
        let that = this
        let activityId = p.match.params.activityId

        fetch(`/api/activities/${activityId}`, {
            method: 'GET',
            // attach token/cookie
            credentials: "same-origin",
        }).then(response => (response.json()))
            .then((activityResult) => {
                if (activityResult.error) {
                    that.setState({ error: activityResult.error })
                }
                if (activityResult._id) {
                    if (this._isMounted) {
                        // when activity is received with id, update state of activity with results
                        this.setState({ activity: activityResult })
                    }
                }
            })

        fetch(`/api/activitiesParticipants/participantList/${activityId}`, {
            method: 'GET',
            credentials: "same-origin",
        })
            .then(response => (response.json()))
            .then((participantList) => {
                if (this._isMounted && participantList.error) {
                    that.setState({ error: participantList.error })
                } else if (this._isMounted) {
                    this.setState({
                        activityParticipants: participantList.participants,
                        nextActivityName: participantList.nextActivityName
                    })
                }
            })
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    getMonthNameFromDate(eventOrActivity) {
        let startMonth = moment(eventOrActivity.startDate).format('MMMM')
        let finishMonth = moment(eventOrActivity.finishDate).format('MMMM')
        let date1 = moment(eventOrActivity.startDate).format('D')
        let date2 = moment(eventOrActivity.finishDate).format('D')
        let out = ''

        if (startMonth === finishMonth && date1 === date2) {
            out = `${startMonth}, ${date1}`
        }
        else if (startMonth === finishMonth) {
            out = `${startMonth}, ${date1}-${date2}`
        } else {
            out = `${startMonth}, ${date1} - ${finishMonth}, ${date2}`
        }
        return out
    }

    onLeaveEventClick() {
        let s = this.state
        let p = this.props
        let activity = s.activity._id
        let eventId = p.match.params.eventId

        fetch(`/api/activitiesParticipants/unsubscribe/${activity}`, {
            method: 'DELETE',
            credentials: 'same-origin',
        })
            .then(response => response.json())
            .then(() => {
                p.history.push(`/event/${eventId}`)
            })
    }

    renderMeOrHost(participant) {
        let activity = this.state.activity
        let p = this.props

        if (participant.participantUserId === p.user._id && activity && activity.creator === participant.participantUserId) {
            return `(Me)(Host)`
        } else if (participant.participantUserId === p.user._id) {
            return `(Me)`
        } else if (activity && activity.creator === participant.participantUserId) {
            return `(Host)`
        }
        return null
    }

    onJoinClick() {
        let s = this.state
        let p = this.props
        let activity = s.activity._id
        let eventId = p.match.params.eventId

        fetch(`/api/activitiesParticipants/new/${eventId}/${activity}`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: { "Content-Type": 'application/json' },
        })
            .then(response => response.json())
            .then(() => {
                p.history.push(`/event/${eventId}/activity/${activity}`)
            })
    }

    render() {
        let p = this.props
        let s = this.state
        let activity = s.activity
        let eventId = p.match.params.eventId
        let isUserParticipant = s.activityParticipants
            .map(participant => (participant.participantUserId))
            .includes(p.user._id)

        return (
            <div className='ActivityComponent'>
                <div className="subContainer20">
                    <i className='mywanderLogo' />
                    <Initials right
                        name={p.user.name} />

                    <i className='questionmark blue'
                        onClick={() => { p.history.push(`/feedback?` + `eventId=${eventId}&` + `activityId=${activity._id}&` + `from=activity`) }} />

                    <div className='titleGrid'>
                        <h1 className='sides20 activityName'>{activity && activity.name}</h1>
                        {isUserParticipant ? < div className='paid'>Paid</div> : null}
                        {activity && activity.creator === p.user._id ?
                            <i className='editPenBlue top'
                                onClick={() => { p.history.push(`/event/${activity.eventId}/activity/${activity._id}/edit`) }} />
                            : null
                        }
                    </div>

                    <div className='locationName'>
                        {activity ? activity.locationName : null}
                    </div>

                    <div className='location'>
                        <i className='mapPin' />
                        {activity && activity.location ? helpers.locationNameFormatter(activity.location) : null}
                    </div>

                    <div className='date'>
                        <i className='calendar' />
                        {activity ? this.getMonthNameFromDate(activity) : null}
                    </div>

                    <span className='activityTime'>
                        <i className='clock' />
                        {activity ? moment(activity.startDate).format('h:mmA') : null} - {activity ? moment(activity.finishDate).format('h:mmA') : null}
                    </span>

                    <div className='cost' >{activity ? activity.costPerson : null}</div>

                    {!isUserParticipant && activity && activity.creator != p.user._id ?
                        <div className='joinMessage'>Join for access to message others</div>
                        :
                        null
                    }

                    <div className='activityParticipants'>
                        <div className="title">
                            <h3>Participants</h3>
                            <button className={'tiel' + (s.activityParticipants.length === 1 || !isUserParticipant ? ' disabled' : '')}
                                onClick={() => {
                                    p.history.push(`/recipients-select?` +
                                        `eventId=${activity.eventId}&` +
                                        `activityId=${activity._id}`)
                                }}>
                                Group message
                            </button>
                        </div>
                        {s.activityParticipants.length > 0 ?
                            <ul className='activityParticipantList'>
                                {s.activityParticipants.map((participant, i) => (
                                    <li className='participant' key={i}>
                                        <h4 className='thin'>{participant.participantName}
                                            <span className='MeOrHost'>{this.renderMeOrHost(participant)}</span>
                                        </h4>

                                        {participant.attending ?
                                            <small>Will be at next activity: {s.nextActivityName}</small>
                                            :
                                            <small>Will not be at next activity</small>
                                        }

                                        {participant.participantUserId != p.user._id ?
                                            <button className={'tealGhost' + (s.activityParticipants.length === 1 || !isUserParticipant ? ' disabled' : '')}
                                                onClick={() => {
                                                    p.history.push(`/chat?` +
                                                        `eventId=${activity.eventId}&` +
                                                        `activityId=${activity._id}&` +
                                                        `recipientId=${participant.participantUserId}&` +
                                                        `from=activity`)
                                                }}>
                                                Message
                                            </button>
                                            : <div className='spaceTaker'></div>
                                        }
                                    </li>
                                )
                                )}
                            </ul>
                            : null
                        }
                    </div>

                    {isUserParticipant && activity && activity.creator != p.user._id ?
                        <div className='leaveActivity'>
                            <button className='tiel'
                                onClick={this.onLeaveEventClick.bind(this)}>Leave activity</button>
                            <span className='alert'>Non refundable</span>
                        </div>
                        :
                        null
                    }

                    {activity && activity.creator === p.user._id ?
                        <button className='tiel delete'
                            onClick={() => { p.history.push(`/event/${activity.eventId}/activity/${activity._id}/confirmdeletion`) }}>
                            Delete activity
                        </button>
                        :
                        null
                    }

                    <div className='bottomPusher' />
                </div>

                {!isUserParticipant ?
                    <div className='footer'>
                        <button className='left ghost circular'
                            onClick={() => { p.history.push('/event/' + s.activity.eventId) }}>
                            <i className="left blue arrow" />
                        </button>

                        <button className="right"
                            onClick={
                                this.onJoinClick.bind(this)
                                // p.history.push(`/event/${activity.eventId}/activity/${activity._id}/pay`)
                            }
                        >
                            {/*<span>{activity && 'Pay ' + activity.costPerson}</span>*/}
                            <span>Join</span>
                        </button>
                    </div>
                    :
                    <div className='footer'>
                        <button className='center'
                            onClick={() => { p.history.push('/event/' + s.activity.eventId) }}>
                            <span>Go to event</span></button>
                    </div>
                }
            </div>
        )
    }
}

let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityComponent))