//libraries
import React from 'react'
import helpers from '../helpers'
import moment from 'moment'
import ReactGA from 'react-ga';

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../__redux/actions'
import { withRouter } from 'react-router-dom'

// components
import Initials from '../elements/Initials'

class EventsComponent extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            event: null,
            activitiesList: [],
            error: null,
            eventParticipantList: [],
            activitiesParticipantsForEvent: []
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true
        let p = this.props
        let that = this

        // event id retrieved from props passed by redux and placed in variable
        let eventId = p.match.params.id
        //relative path that will be added to tail of domain to make a call
        //ex: localhost/event/5ccf039defb880387836305c
        fetch(`/api/events/${eventId}`, {
            method: 'GET',
            // attach token/cookie
            credentials: "same-origin",
        }).then(response => (response.json()))
            .then((result) => {
                if (result.error) {
                    that.setState({ error: result.error })
                }
                if (result._id) {
                    if (this._isMounted) {
                        // when event is received with id, update state of event with results
                        this.setState({ event: result })
                    }
                }
            });

        fetch(`/api/activities/${eventId}/list`, {
            method: 'GET',
            credentials: "same-origin",
        })
            .then(response => (response.json()))
            .then((activitiesList) => {
                if (this._isMounted && activitiesList.error) {
                    that.setState({ error: activitiesList.error })
                } else if (this._isMounted) {
                    // when activity is received with id, update state of activities with results
                    this.setState({ activitiesList })
                }
            })

        fetch(`/api/eventsParticipants/list/${eventId}`, {
            method: 'GET',
            credentials: "same-origin",
        })
            .then(response => (response.json()))
            .then((eventParticipantList) => {
                if (this._isMounted && eventParticipantList.error) {
                    that.setState({ error: eventParticipantList.error })
                } else if (this._isMounted) {
                    this.setState({ eventParticipantList })
                }
            })

        fetch(`/api/activitiesParticipants/list/${eventId}`, {
            method: 'GET',
            credentials: "same-origin",
        })
            .then(response => (response.json()))
            .then((listOfParticipantsOfActivitiesForEvent) => {
                if (this._isMounted && listOfParticipantsOfActivitiesForEvent.error) {
                    that.setState({ error: listOfParticipantsOfActivitiesForEvent.error })
                } else if (this._isMounted) {
                    this.setState({ activitiesParticipantsForEvent: listOfParticipantsOfActivitiesForEvent })
                }
            })
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    getMonthNameFromDate(eventOrActivity) {
        let startMonth = moment(eventOrActivity.startDate).format('MMMM')
        let finishMonth = moment(eventOrActivity.finishDate).format('MMMM')
        let date1 = moment(eventOrActivity.startDate).format('D')
        let date2 = moment(eventOrActivity.finishDate).format('D')
        let out = ''

        if (startMonth === finishMonth && date1 === date2) {
            out = `${startMonth}, ${date1}`
        }
        else if (startMonth === finishMonth) {
            out = `${startMonth}, ${date1}-${date2}`
        } else {
            out = `${startMonth}, ${date1} - ${finishMonth}, ${date2}`
        }
        return out
    }

    renderRightButton() {
        let s = this.state
        let p = this.props
        let eventId = p.match.params.id

        if (s.event && s.event.creator != p.user._id && s.eventParticipantList
            .map(participant => (participant.participantUserId))
            .includes(p.user._id)) {
            return (<button className='right'
                onClick={() => { p.history.push(`/event/${eventId}/activity/new`) }}>
                <span>Add activity</span>
            </button>)
        }

        if (s.event && s.event.creator === p.user._id) {
            return (<button className='right'
                onClick={() => {
                    p.history.push(`/event/${eventId}/invite`),
                    ReactGA.event({ category: 'Invite Others', action: 'Open' })
                }}>
                <span>Invite others</span>
            </button>)
        } else {
            return (<button className='right' onClick={this.onJoinTripButtonClick.bind(this)}>
                <span>Join trip</span>
            </button>)
        }
    }

    onJoinTripButtonClick() {
        let p = this.props
        let eventId = p.match.params.id

        fetch(`/api/eventsParticipants/new/${eventId}`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: { "Content-Type": 'application/json' },
        })
            .then(response => response.json())
            .then(() => {
                p.history.push(`/event/${eventId}`)
            })
    }


    onLeaveEventClick() {
        let p = this.props
        let eventId = p.match.params.id

        fetch(`/api/eventsParticipants/unsubscribe/${eventId}`, {
            method: 'DELETE',
            credentials: 'same-origin',
        })
            .then(response => response.json())
            .then(() => {
                p.history.push(`/event/${eventId}`)
            })
    }

    amIaEventParticipant() {
        return this.state.eventParticipantList
            .map(participant => (participant.participantUserId))
            .includes(this.props.user._id)
    }

    onActivityClick(activity) {
        let p = this.props

        if (p.user._id === activity.creator || this.amIaEventParticipant()) {
            p.history.push(`/event/${p.match.params.id}/activity/${activity._id}`)
        }
    }

    renderMeOrHost(participant) {
        let p = this.props
        let event = this.state.event

        if (participant.participantUserId === p.user._id && event && event.creator === participant.participantUserId) {
            return `(Me)(Host)`
        } else if (participant.participantUserId === p.user._id) {
            return `(Me)`
        } else if (event && event.creator === participant.participantUserId) {
            return `(Host)`
        }
        return null
    }

    render() {
        let event = this.state.event
        let p = this.props
        let s = this.state
        let eventId = p.match.params.id
        const amIaParticipant = (activity) => {
            return s.activitiesParticipantsForEvent.find(ap => ap.activityId === activity._id && ap.participantUserId === p.user._id)
        }

        return (
            <div className='EventsComponent'>

                <div className="subContainer20">

                    <i className='mywanderLogo' />

                    <Initials right
                        name={p.user.name} />

                    <i className='questionmark blue'
                        onClick={() => { p.history.push(`/feedback?` + `eventId=${eventId}&` + `from=event`) }} />

                    <div className='titleGrid'>
                        <h1 className='sides20 eventName'>{event && event.name}</h1>
                        {event && event.creator === p.user._id ?
                            <i className='editPenBlue top'
                                onClick={() => { p.history.push(`/event/${event._id}/edit`) }} />
                            : null}
                    </div>

                    <div className='locationName'>
                        {event ? event.locationName : null}
                    </div>

                    <div className='location'>
                        <i className='mapPin' />
                        {event && event.location ? helpers.locationNameFormatter(event.location) : null}
                    </div>

                    <div className='date'>
                        <i className='calendar' />
                        {event ? this.getMonthNameFromDate(event) : null}
                    </div>

                    {s.activitiesList.length > 0 ?
                        <ul className='activities'>
                            {s.activitiesList.map((activity, index) => (

                                <li key={index}
                                    onClick={this.onActivityClick.bind(this, activity)}
                                    className={'activity' +
                                        (p.user._id === activity.creator || amIaParticipant(activity) ? ' active' : '')}>

                                    <span className='activityName'>
                                        {activity.name}

                                        {amIaParticipant(activity) && <span key={index} className="dot" />}

                                        {activity.creator === p.user._id ?
                                            <i
                                                className='editPenBlue'
                                                onClick={(e) => {
                                                    p.history.push(`/event/${eventId}/activity/${activity._id}/edit`)
                                                    e.stopPropagation()
                                                }}
                                            /> :
                                            <Initials creatorInitials
                                                name={activity.creatorName} />
                                        }
                                    </span>

                                    <div className='activityDate'>
                                        {this.getMonthNameFromDate(activity)}
                                    </div>

                                    <div className='timeAndCost'>
                                        <span className='activityTime'>
                                            <i className='clock' />
                                            {moment(activity.startDate).format('h:mmA')} - {moment(activity.finishDate).format('h:mmA')}
                                        </span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        : null
                    }

                    <div className='eventParticipantList' >
                        {s.eventParticipantList.length > 0 ?
                            <ul>
                                <div className='title'>Participants</div>
                                {s.eventParticipantList.map((participant, index) =>
                                    <li className='participants' key={index}>
                                        {participant.participantName}
                                        <span className='MeOrHost'>{this.renderMeOrHost(participant)}</span>
                                    </li>
                                )}
                            </ul> : null
                        }

                        {event && event.creator === p.user._id ?
                            <button
                                className='tiel'
                                onClick={() => {
                                    p.history.push(`/event/${eventId}/activity/new`),
                                    ReactGA.event({ category: 'Activity', action: 'Create New' })
                                }}>
                                Add activity
                            </button>
                            : null
                        }
                        {this.amIaEventParticipant() && event && event.creator != p.user._id ?
                            <button
                                className='tiel'
                                onClick={this.onLeaveEventClick.bind(this)}>Leave event</button>
                            :
                            <button className='tiel'
                                onClick={() => { p.history.push(`/event/${eventId}/confirmdeletion`) }}>
                                Delete event
                            </button>
                        }

                    </div>
                    <div className='bottomPusher' />
                </div>

                <div className="footer">
                    <button
                        className='left ghost circular'
                        onClick={() => { p.history.push('/profile') }}><i className="left blue arrow" />
                    </button>

                    {this.renderRightButton()}
                </div>


            </div>
        )
    }
}


let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventsComponent))
