import Cookies from 'js-cookie'

// user actions
export const signin = (user, cb = () => { }) => (
    function (dispatch) {
        fetch('/api/auth/signin', {
            method: 'POST',
            headers: { "Content-Type": 'application/json' },
            body: JSON.stringify({
                email: user.email,
                password: user.password
            })
        }).then(response => response.json())
            .then(result => {
                if (result.token) {
                    Cookies.set('token', result.token, { expires: 1 });
                    dispatch({ type: 'setUser', user: result.user });
                    cb(result.user)
                } else if (result.error) {
                    cb({ error: result.error })
                }
            });
    }
) //signin

export const setUser = (user) => ({
    type: 'setUser', user
})

export const getUser = (cb = () => { }) => (
    function (dispatch) {
        fetch('/api/user', {
            method: 'GET',
            credentials: 'same-origin'
        }).then(response => response.json())
            .then(result => {
                if (!result) {
                    cb({ error: 'no result' })
                }
                else if (result.error && result.error === 'unauthorized') {
                    Cookies.remove('token');
                    cb(result.error)
                } else {
                    dispatch({ type: 'setUser', user: result });
                    cb(result)
                }
            })
    }
) //getUser


export const updateUser = (payload, cb = () => { }) => (
    function ( /*dispatch */) {
        // call to server
        fetch('/api/user', {
            // PUT method is used to make updates in server
            method: 'PUT',
            // sends the http json header to the browser to inform him what the kind of a data he expects.
            headers: { 'Content-Type': 'application/json' },
            // attach a cookie
            credentials: "same-origin",
            // Converts a JavaScript object into a string
            body: JSON.stringify(payload) // user information that is being sent to server
        })
            .then(res => res.json())
            .then(res => {
                // if there were changes made
                if (res.resultOfUpdate && res.resultOfUpdate.nModified === 1) {
                    // trigger callback with response, which is an empty object
                    cb(res)
                }
                // if any errors, callback will be triggered with error from server
                if (res.error) cb({ error: res.error })

            })
    }
) //updateUser


// temp actions
export const setSelectedContacts = (contactIds) => ({
    type: 'setSelectedContacts',
    contactIds
})

export const cleanSelectedContacts = () => ({
    type: 'cleanSelectedContacts'
})
