//libraries
import React from 'react'

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../../../__redux/actions'
import { withRouter } from 'react-router-dom'

//components
import Input from '../../../elements/Input'
import Initials from '../../../elements/Initials'

class EditDeleteContactComponent extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            error: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        this._isMounted = true
        let contactId = this.props.match.params.contactId

        if (contactId) {
            fetch(`/api/contacts/${contactId}`, {
                method: 'GET',
                credentials: 'same-origin'
            }).then(response => response.json())
                .then(result => {
                    if (result.error && result.error.key === 'unauthorized') {
                        // Cookies.remove('token'); // TODO: bring back once stable
                    } else {
                        if (this._isMounted) {
                            this.setState(Object.assign(this.state, result))
                        }
                    }
                })
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    // checking for any value changes in inputs
    onContactInputsChange(type, value) {
        // if any value changes in any of the type state, it will update the state
        this.setState({ [type]: value })
    }

    onUpdateClick() {
        let s = this.state
        let p = this.props
        let that = this
        let contactId = p.match.params.contactId
        let eventId = p.match.params.eventId
        let payload = {
            name: s.name,
            phone: s.phone,
            email: s.email
        }

        // relative path that will be added to tail of domain to make a call
        fetch(`/api/contacts/${contactId}/update`, {
            method: 'PUT',
            // only authorized users, attach token/cookie
            credentials: "same-origin",
            headers: { "Content-Type": 'application/json' },
            // information/ payload being sent to server
            body: JSON.stringify(payload)
        }).then(response => (response.json()))
            // response from server
            .then((result) => {
                if (result.error) {
                    that.setState({ error: result.error })
                }
                p.history.push(`/event/${eventId}/invite/contacts`)
            });
    }

    onDeleteContactClick() {
        let p = this.props
        let contactId = p.match.params.contactId
        // let activityId = p.match.params.activityId
        let eventId = p.match.params.eventId

        fetch(`/api/contacts/delete/${contactId}`, {
            method: 'DELETE',
            credentials: 'same-origin',
        })
            .then(response => response.json())
            .then(() => {
                p.history.push(`/event/${eventId}/invite/contacts`)
            })
    }

    render() {
        let p = this.props
        let s = this.state
        let eventId = p.match.params.eventId

        return (
            <div className='EditDeleteContactComponent'>
                <div className="subContainer20">
                    <Initials right
                        name={p.user.name} />
                    <i className='mywanderLogo' />
                    <h1>Update contact</h1>

                    <Input
                        placeholder='Name'
                        value={s.name}
                        onChange={this.onContactInputsChange.bind(this, 'name')}
                        error={s.error && s.error.key === 'name' ? s.error.message : null}
                    />
                    <Input
                        type='tel'
                        placeholder='Phone'
                        value={s.phone}
                        onChange={this.onContactInputsChange.bind(this, 'phone')}
                        error={s.error && s.error.key === 'phone' ? s.error.message : null}
                    />
                    <Input
                        placeholder='Email'
                        value={s.email}
                        onChange={this.onContactInputsChange.bind(this, 'email')}
                        error={s.error && s.error.key === 'email' ? s.error.message : null}
                    />

                    <button className='tiel'
                        onClick={this.onDeleteContactClick.bind(this)}> Delete contact</button>

                    {/* <div className='bottomPusher' /> */}
                </div>

                <div className="footer">
                    <button
                        className='left ghost circular' onClick={() => { p.history.push(`/event/${eventId}/invite/contacts`) }}>
                        <i className="left blue arrow" />
                    </button>

                    <button className='right' onClick={this.onUpdateClick.bind(this)}><span>Update</span></button>
                </div>
            </div>
        )
    }

}

let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditDeleteContactComponent))