// libraries
import React from 'react'
import Input from '../Input'


class DateTimeElement extends React.Component {


    render() {
        let p = this.props

        return (
            <div className={'DateTimeElement' +
                (p.class ? ' ' + p.class : '')} >

                <h2 className='start'>{p.label}</h2>

                <Input
                    type='date'
                    class="date"
                    placeholder='Date'
                    icon="calendar"
                    value={p.dateValue}
                    onChange={p.onDateChange.bind(this)}
                    error={p.dateError} />

                <Input
                    class="time"
                    placeholder='Time'
                    icon="clock"
                    type='time'
                    value={p.dateValue}
                    onChange={p.onDateChange.bind(this)}
                    error={p.timeError} />
            </div>
        )
    }
}



export default DateTimeElement
