//libraries
import React from 'react'

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../__redux/actions'
import { withRouter } from 'react-router-dom'

//components
import Input from '../elements/Input'

class ForgotPassword extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            email: '',
            code: '',
            page: 'credentials', // credentials, code, newPassword
            newPassword: '',
            error: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    onPasswordRecoveryChange(type, value) {
        this.setState({ [type]: value })
    }

    onRightButtonClick() {
        let that = this
        let email = this.state.email
        let code = this.state.code

        if (this.state.page === 'credentials') {

            fetch('/api/recovery/getCode', {
                method: 'POST',
                headers: { "Content-Type": 'application/json' },
                body: JSON.stringify({ email })
            })
                .then(response => (response.json()))
                // response from server
                .then((result) => {
                    // if response has errors
                    if (result.error) {
                        // update error state
                        that.setState({ error: result.error })
                    } else {
                        that.setState({ page: 'code' })
                    }
                });
        }

        if (this.state.page === 'code') {

            fetch('/api/recovery/validateCode', {
                method: 'POST',
                headers: { "Content-Type": 'application/json' },
                body: JSON.stringify({ code, email })
            })
                .then(response => (response.json()))
                // response from server
                .then((result) => {
                    // if response has errors
                    if (result.error) {
                        // update error state
                        that.setState({ error: result.error })
                    } else {
                        that.setState({ page: 'newPassword' })
                    }
                });
        }
    }

    onGetNewCodeClick() {
        let that = this
        let email = this.state.email

        fetch(`/api/recovery/${email}`, {
            method: 'PUT',
            headers: { "Content-Type": 'application/json' },
        })
            .then(response => (response.json()))
            // response from server
            .then((result) => {
                // if response has errors
                if (result.error) {
                    // update error state
                    that.setState({ error: result.error })
                } else {
                    that.setState({ page: 'code' })
                }
            });
    }

    onChangePasswordClick() {
        let p = this.props
        let that = this
        let newPassword = this.state.newPassword
        let email = this.state.email
        let code = this.state.code

        fetch(`/api/recovery/newPassword`, {
            method: 'PUT',
            headers: { "Content-Type": 'application/json' },
            body: JSON.stringify({ email, code, newPassword })
        })
            .then(response => (response.json()))
            // response from server
            .then((result) => {
                // if response has errors
                if (result.error) {
                    // update error state
                    that.setState({ error: result.error })
                } else {
                    p.history.push('/signin')
                }
            });
    }


    onLeftButtonClick() {
        if (this.state.page === 'credentials') {
            this.props.history.push('/signin')
        } else {
            this.setState({ page: 'credentials' })
        }
    }

    plotBody() {
        let s = this.state

        if (this.state.page === 'credentials') return (
            <Input
                placeholder='Enter your email'
                value={this.state.email}
                onChange={this.onPasswordRecoveryChange.bind(this, 'email')}
                error={s.error && s.error.key === 'email' ? s.error.message : null}
            />)

        if (this.state.page === 'code') {
            let body = []
            body.push(<Input
                key='codeInput'
                placeholder='Enter code'
                value={this.state.code}
                onChange={this.onPasswordRecoveryChange.bind(this, 'code')}
                error={s.error && s.error.key === 'code' ? s.error.message : null}
            />)
            body.push(<button key='button' className='tiel' onClick={this.onGetNewCodeClick.bind(this)}>Get new code</button>)
            return body
        }

        if (this.state.page === 'newPassword') return (
            <Input
                placeholder='Enter new password'
                value={this.state.newPassword}
                onChange={this.onPasswordRecoveryChange.bind(this, 'newPassword')}
                error={s.error && s.error.key === 'newPassword' ? s.error.message : null}
            />)
    }

    render() {
        return (
            <div className='ForgotPassword'>
                <div className="subContainer20">
                    <i className='mywanderLogo' />
                    <h1>Password recovery</h1>
                    {this.plotBody()}
                </div>

                {this.state.page != 'newPassword' ?
                    <div className="footer">
                        <button className='left ghost circular' onClick={this.onLeftButtonClick.bind(this)}><i className="left blue arrow" /></button>

                        <button className="right circular" onClick={this.onRightButtonClick.bind(this)}><i className="right white arrow" /></button>
                    </div> :
                    <div className="footer">
                        <button className='center' onClick={this.onChangePasswordClick.bind(this)}><span>Change password</span></button>
                    </div>}
            </div>
        )
    }
}

let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword))
