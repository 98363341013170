// libraries
import React from 'react'

class InitialsElement extends React.Component {

    generateInitials() {
        let p = this.props
        if (!p.name) return '--'

        let name = p.name
        let fullName = name.split(' ')
        let out = ''

        if (fullName[0]) out += fullName[0].charAt(0)
        if (fullName[1]) out += fullName[1].charAt(0)

        return out
    }

    render() {
        let p = this.props
        return (
            <div className=
                {'InitialsElement' + (p.right ? ' right' : '')
                    + (p.creatorInitials ? ' creatorInitials' : '')}>
                <span className='initials'>{this.generateInitials()}</span>
            </div>
        )
    }
}

export default InitialsElement
