import React from 'react'
// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../__redux/actions'
import { withRouter } from 'react-router-dom'

//components
import Initials from '../elements/Initials'
import qs from 'query-string'



class FeedbackComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: '',
            description: '',
            error: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onContactUsInputsChange(type, e) {
        // if any value changes in any of the type state, it will update the state
        this.setState({ [type]: e.target.value })
    }

    onSendClick() {
        let s = this.state
        let p = this.props
        const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        const eventId = queryParams.eventId
        const activityId = queryParams.activityId
        let payload = {
            subject: s.subject,
            description: s.description,
        }

        fetch(`/api/feedback/`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: { "Content-Type": 'application/json' },
            body: JSON.stringify(payload)
        })
            .then(response => (response.json()))
            .then(() => {

                if (queryParams.from === 'activity') {
                    p.history.push(`/event/${eventId}/activity/${activityId}`)
                }
                else if (queryParams.from === 'messages') {
                    p.history.push('/messages')
                }
                else if (queryParams.from === 'event') {
                    p.history.push(`/event/${eventId}`)
                }
                else if (queryParams.from === 'profile') {
                    p.history.push('/profile')
                }
            })
    }

    onBackArrowClick() {
        const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        const eventId = queryParams.eventId
        const activityId = queryParams.activityId

        let p = this.props
        if (queryParams.from === 'activity') {
            p.history.push(`/event/${eventId}/activity/${activityId}`)
        }
        else if (queryParams.from === 'messages') {
            p.history.push('/messages')
        }
        else if (queryParams.from === 'event') {
            p.history.push(`/event/${eventId}`)
        }
        else if (queryParams.from === 'profile') {
            p.history.push('/profile')
        }
    }

    render() {
        let p = this.props
        let s = this.state

        return (
            <div className='FeedbackComponent'>
                <div className="subContainer20">
                    <i className='mywanderLogo' />
                    <Initials right
                        name={p.user.name} />
                    <h1 className="sides20">Contact us</h1>

                    <form>
                        <div className='subject'>Subject:</div>
                        <input
                            className='descriptionInput'
                            type="text"
                            value={s.subject}
                            onChange={this.onContactUsInputsChange.bind(this, 'subject')}
                        />

                        <div className='description'>Description:</div>
                        <textarea
                            className='descriptionInput'
                            type="text"
                            rows="10"
                            value={s.description}
                            onChange={this.onContactUsInputsChange.bind(this, 'description')}
                        >
                        </textarea>
                    </form>
                    <div className='bottomPusher' />
                </div>

                <div className='footer'>
                    <button className='left ghost circular'
                        onClick={this.onBackArrowClick.bind(this)}>
                        <i className="left blue arrow" />
                    </button>

                    <button
                        className={'right' +
                            (s.subject.length === 0 && s.description.length === 0 ?
                                ' disabled' : '')}
                        onClick={this.onSendClick.bind(this)}>
                        <span>Send</span>
                    </button>
                </div>
            </div>
        )
    }
}

let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeedbackComponent))