export default (user = {}, action) => {
    switch (action.type) {

    case "setUser":
        return action.user

    default:
        return user;
    }
};
