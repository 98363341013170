export default {
    locationNameFormatter: location => {
        let locationString = location
        if (location.state_ansi) { locationString += ', ' + location.state_ansi } else if (location.province) { locationString += ', ' + location.province }
        if (location.iso3) locationString += ', ' + location.iso3
        if (!location.iso3 && location.iso2) locationString += ', ' + location.iso2

        return locationString
    },

    placeNameFormatter: place => {
        let placeString = place.city
        if (place.state_ansi) { placeString += ', ' + place.state_ansi } else if (place.province) { placeString += ', ' + place.province }
        if (place.iso3) placeString += ', ' + place.iso3
        if (!place.iso3 && place.iso2) placeString += ', ' + place.iso2
        return placeString

    }
}