import React, { useState, useEffect, } from 'react'
import moment from 'moment'
// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../../__redux/actions'
import { withRouter } from 'react-router-dom'
//components
import Initials from '../../elements/Initials'
import qs from 'query-string'

const readMessagesCall = async (messagesIds) => {
    const sendReads = await fetch('/api/messages/read', {
        method: 'POST',
        credentials: "same-origin",
        headers: { "Content-Type": 'application/json' },
        body: JSON.stringify(messagesIds)
    })
    const res = await sendReads.json()
    return res
}

// functional component which takes props as an argument and returns a react element.
const MessageComponent = (props) => {
    // react state hooks - [Declare a new state variable, to update state call this declared variable]
    const [text, setText] = useState('');
    const [messages, setMessages] = useState([]);
    const [activityName, setActivityName] = useState([]);
    const [eventName, setEventName] = useState([]);
    const [participantCount, setParticipantCount] = useState([]);
    const [conversationParticipantsNames, setConversationParticipantsNames] = useState([]);
    const [isConversationParticipantsNamesShown, setIsConversationParticipantsNamesShown] = useState(false)

    const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    const eventId = queryParams.eventId
    const activityId = queryParams.activityId
    const recipientId = queryParams.recipientId
    const groupId = queryParams.groupId



    useEffect(() => {
        window.scrollTo(0, 0);

        let isSubscribed = true

        const payload = { activityId }
        if (recipientId) payload.recipientId = recipientId
        if (groupId) payload.groupId = groupId

        const fetchMessages = async () => {
            const messagesResult = await fetch('/api/messages/get', {
                method: 'POST',
                credentials: "same-origin",
                headers: { "Content-Type": 'application/json' },
                body: JSON.stringify(payload)
            })
            const result = await messagesResult.json()
            if (isSubscribed) {
                setConversationParticipantsNames(
                    result.messagesRecipients.map(p => p.name).sort(function (a, b) {
                        var textA = a.toUpperCase();
                        var textB = b.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }))
                setMessages(result.messages)
                setActivityName(result.activity.name)
                setEventName(result.event.name)
                readMessagesCall(result.messages.map(m => m._id))
                setParticipantCount(result.messagesRecipients.length)
            }
        }
        fetchMessages()
        return () => isSubscribed = false
    }, []);

    async function onTextSendClick() {
        const payload = { activityId, text }
        if (recipientId) payload.recipientId = recipientId
        if (groupId) payload.groupId = groupId

        const response = await fetch(`/api/messages/new`, {
            method: 'POST',
            credentials: "same-origin",
            headers: { "Content-Type": 'application/json' },
            body: JSON.stringify(payload)
        })
        const result = await response.json()
        setMessages(messages.concat(result.insertedMessage))
        setText('')
    }

    const renderNamesDropdown = () => {
        return conversationParticipantsNames.map((name, i) => (
            <div key={i}>{name}</div>
        ))
    }

    const onBackArrowClick = () => {
        if (queryParams.from === 'activity') {
            props.history.push(`/event/${eventId}/activity/${activityId}`)
        }
        else if (queryParams.from === 'messages') {
            props.history.push('/messages')
        }
    }

    return (
        <div className='MessageComponent'>
            <div className='subContainer20'>
                <i className='mywanderLogo' />
                <Initials right
                    name={props.user.name} />
                <div className='titleGrid'>
                    <h6 className='eventName'>{eventName}</h6>
                    <h1 className="sides20 activityName">{activityName}</h1>

                    <div className='participantInfo'>
                        <span className='participantNum'
                            onMouseEnter={() => setIsConversationParticipantsNamesShown(true)}
                            onMouseLeave={() => setIsConversationParticipantsNamesShown(false)}>
                            Participants ({participantCount})
                        </span>

                        {isConversationParticipantsNamesShown && (
                            <div className='namesDropdown'
                                onMouseEnter={() => setIsConversationParticipantsNamesShown(true)}
                                onMouseLeave={() => setIsConversationParticipantsNamesShown(false)}>
                                {renderNamesDropdown()}
                            </div>
                        )}
                    </div>
                </div>

                {messages && messages.length > 0 ?
                    <ul className='messages'>
                        {messages.map(m => {
                            if (m.text) {
                                if (m.creatorId === props.user._id) {
                                    return <li key={m._id} className='mFromUser'>{m.text}</li>
                                } else {
                                    return <li key={m._id} className='mFromContact'>
                                        <div className='SenderDetails'>
                                            <span className='sender'>{m.creatorName}</span>
                                            <span className='timeAgo'>{moment(m.ts).fromNow(true)}</span>
                                        </div>
                                        <div className='textFromContact'>{m.text}</div>
                                    </li>
                                }
                            }
                        })}
                    </ul>
                    : null
                }
                <div className='bottomPusher' />
            </div>

            <div className="footer">
                <button
                    className='left ghost circular'
                    onClick={onBackArrowClick}>
                    <i className="left blue arrow" />
                </button>
                <div className="messageInput">
                    <input
                        placeholder='Message'
                        value={text}
                        onChange={e => { setText(e.target.value) }} type="text">
                    </input>
                    <button
                        className={'circular' + (text.length === 0 ? ' disabled' : '')}
                        onClick={onTextSendClick}>
                        <i className="right white arrow" />
                    </button>
                </div>

            </div>
        </div>
    )
}

let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessageComponent))