// react
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import Cookies from 'js-cookie'
import ReactGA from 'react-ga';

// router
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { withRouter } from 'react-router-dom'

// redux
import { Provider } from 'react-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from './__redux/actions'
import configureStore from './__redux/configureStore';
import withAuth from './app/withAuth'

// assets
import './index.scss'

//Components
import SignIn from './SignIn'
import SignUp from './SignUp'
import Profile from './Profile'
import Events from './Events'
import Messages from './Messages'
import Message from './Messages/Message'
import CreateEditEvent from './Events/CreateEditEvent'
import CreateEditActivity from './Events/CreateEditActivity'
import InviteOthers from './InviteOthers'
import Contacts from './InviteOthers/Contacts'
import EventParticipants from './InviteOthers/EventParticipants'
import EditDeleteContact from './InviteOthers/Contacts/EditDeleteContact'
import ForgotPassword from './ForgotPassword'
import Activity from './Events/Activity'
import Pay from './Events/Activity/Pay'
import ActivityContacts from './Events/Activity/ActivityContacts'
import Div100vh from 'react-div-100vh'
import ConfirmDeletion from './Events/Activity/ConfirmDeletion'
import ConfirmEventDeletion from './Events/ConfirmEventDeletion'
import Feedback from './Feedback'

ReactGA.initialize("UA-137164668-1")

const App = (props) => {
    // getting user name so it can be available for all components to get InitialsComponent

    useEffect(() => {
        let token = Cookies.get('token')
        if (!props.user.name && token) props.actions.getUser()
    })

    // if (!props.user.name) return "loading"
    return (
        <Div100vh className='ContainerComponent' >
            <Switch>
                <Route exact path="/" component={SignIn} />
                <Route path="/signin" component={SignIn} />
                <Route path="/signup" component={SignUp} />

                <Route path="/recovery" component={ForgotPassword} />

                <Route path="/profile" component={withAuth(Profile)} />

                <Route path="/feedback" component={withAuth(Feedback)} />

                <Route path="/event/:eventId/activity/:activityId/groupMessage" component={withAuth(Messages)} />
                <Route path="/event/:eventId/activity/:activityId/message" component={withAuth(Messages)} />
                <Route path="/event/:eventId/activity/:activityId/confirmdeletion" component={withAuth(ConfirmDeletion)} />
                <Route path="/event/:eventId/confirmdeletion" component={withAuth(ConfirmEventDeletion)} />
                <Route path="/recipients-select" component={withAuth(ActivityContacts)} />
                <Route path="/chat" component={withAuth(Message)} />
                <Route path="/messages" component={withAuth(Messages)} />
                <Route path="/newmessage/contacts" component={withAuth(Contacts)} />

                <Route path="/event/new" component={withAuth(CreateEditEvent)} />

                <Route path="/event/:eventId/activity/:activityId/pay" component={withAuth(Pay)} />
                <Route path="/event/:eventId/activity/:activityId/edit" component={withAuth(CreateEditActivity)} />
                <Route path="/event/:eventId/activity/new" component={withAuth(CreateEditActivity)} />
                <Route path="/event/:eventId/activity/:activityId" component={withAuth(Activity)} />

                <Route path="/event/:eventId/contact/:contactId/edit" component={withAuth(EditDeleteContact)} />
                <Route path="/event/:eventId/invite/contacts" component={withAuth(Contacts)} />
                <Route path="/event/:eventId/:activityId/invite" component={withAuth(EventParticipants)} />
                <Route path="/event/:eventId/invite" component={withAuth(InviteOthers)} />

                <Route path="/event/:id/edit" component={withAuth(CreateEditEvent)} />
                <Route path="/event/:id" component={withAuth(Events)} />

                <Route component={SignIn} />
            </Switch>
        </Div100vh >
    )
}

// withAuth(Events)
let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
let ConnectedApp = withRouter(connect(mapStateToProps, mapDispatchToProps)(App))

let initialState = {
    // user: {}, // user profile with phone and other things
    // temp: {
    //      selectedContacts: [] // array of ids of contacts
    //}
}
let store = configureStore(initialState)

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <ConnectedApp />
        </BrowserRouter>
    </Provider>
    , document.getElementById('app')
)

