// libraries
import React from 'react'
import Cookies from 'js-cookie'
import ReactGA from 'react-ga';

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../__redux/actions'
import { withRouter } from 'react-router-dom'

//components
import Input from '../elements/Input'


class SignUpComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            password: '',
            error: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    // checking for any value changes in inputs
    onSignUpInputsChange(type, value) {
        // if any value changes in any of the type state, it will update the state
        this.setState({ [type]: value })
    }

    onCreateClick() {
        let that = this
        let p = this.props

        // relative path that will be added to tail of domain to make a call
        fetch('/api/auth/signup', {
            method: 'POST',
            // credentials: "same-origin",
            headers: { "Content-Type": 'application/json' },
            body: JSON.stringify({
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                password: this.state.password
            })
        }).then(response => {
            return response.json();
        }).then((result) => {
            if (result.token) {
                Cookies.set('token', result.token, { expires: 1 });
                p.actions.setUser(result.user)
                setTimeout(p.history.push('/profile'), 300)
            } else if (result.error) {
                ReactGA.event({ category: 'Sign up', action: 'sign up completed' })
                that.setState({ error: result.error })
            }
        });
    }

    render() {
        let s = this.state
        return (
            <div className='SignUpComponent'>
                <div className="subContainer20">
                    <i className='mywanderLogo' />

                    <h1 className="sides20">Sign Up</h1>

                    <Input
                        placeholder='Enter your name'
                        value={this.state.name}
                        onChange={this.onSignUpInputsChange.bind(this, 'name')}
                        error={s.error && s.error.key === 'name' ? s.error.message : null} />

                    <Input
                        placeholder='Enter your phone'
                        type='tel'
                        value={this.state.phone}
                        onChange={this.onSignUpInputsChange.bind(this, 'phone')}
                        error={s.error && s.error.key === 'phone' ? s.error.message : null}
                    />

                    <Input
                        type='email'
                        placeholder='Enter your email'
                        value={this.state.email}
                        onChange={this.onSignUpInputsChange.bind(this, 'email')}
                        error={s.error && s.error.key === 'email' ? s.error.message : null}
                    />

                    <Input
                        placeholder='Enter your password'
                        type='password'
                        value={this.state.password}
                        onChange={this.onSignUpInputsChange.bind(this, 'password')}
                        error={s.error && s.error.key === 'password' ? s.error.message : null}
                    />

                </div>


                <div className="footer">
                    <a className="left bold"
                        onClick={() => { this.props.history.push('/signin') }} >Sign In</a>
                    <button className="right circular"
                        onClick={this.onCreateClick.bind(this)}
                    ><i className="right white arrow" /></button>
                </div>

            </div>
        )
    }
}


let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUpComponent))
