import { combineReducers } from 'redux';
import user from './userReducer';
import temp from './tempReducer';


const rootReducer = combineReducers({
    user,
    temp
})

export default rootReducer
