//libraries
import React from 'react'

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../../__redux/actions'
import { withRouter } from 'react-router-dom'

//components
import Initials from '../../elements/Initials'
// import { stringify } from 'querystring';

class ContactsComponent extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            userContacts: [],
            eventContactsIds: [],
            selectedContacts: []
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        let p = this.props
        this._isMounted = true

        if (p.temp.selectedContacts) this.setState({ selectedContacts: p.temp.selectedContacts })

        let eventId = this.props.match.params.eventId

        // call to server to fetch list of all contacts of the user
        fetch(`/api/contacts/list`, {
            method: 'GET',
            credentials: 'same-origin'
        })
            .then(response => response.json())
            // once received complete list of contacts from contacts collection in database
            .then(contactsFromDB => {
                if (this._isMounted) {
                    this.setState({
                        userContacts: contactsFromDB,
                    })
                }
            })

        eventId ?
            // fetch contacts from invites collection in database by eventId
            fetch(`/api/events/${eventId}/contacts`, {
                method: 'GET',
                credentials: 'same-origin'
            })
                .then(response => response.json())
                // once received contact ids from contacts in invites collection associated with event id
                .then(contactsIdsForEventFromDb => {
                    if (this._isMounted) {
                        this.setState({
                            eventContactsIds: contactsIdsForEventFromDb
                        })
                    }
                }) : null;

    }

    componentWillUnmount() {
        this._isMounted = false
    }

    onSelectClick() {
        let s = this.state
        let p = this.props
        let eventId = p.match.params.eventId

        // updating global state with concatenated array of event contact ids and selected contacts
        p.actions.setSelectedContacts(
            s.eventContactsIds.concat(s.selectedContacts)
        )
        p.history.push(`/event/${eventId}/invite`)

    }

    // when selecting/clicking on a contact, this function receives the contactId
    onContactClick(contactId) {
        let s = this.state
        // checking if contact already has been selected in order not to duplicate
        let selectedContacts = s.selectedContacts.includes(contactId) ?
            s.selectedContacts.filter(id => id !== contactId) :
            // if contactId does not exist in last to lists,
            // then update state of selectedContacts with new contactId added to existing array
            s.selectedContacts.concat(contactId)

        this.setState({ selectedContacts })
    }

    render() {
        let p = this.props
        let s = this.state
        let eventId = p.match.params.eventId
        let contactHighlightClass = (id) => (
            s.eventContactsIds.concat(s.selectedContacts).includes(id) ? ' active' : ''
        )

        return (
            <div className='ContactsComponent'>
                <div className="subContainer20">
                    <Initials right
                        name={p.user.name} />
                    <i className='mywanderLogo' />
                    <h1>Select contact</h1>

                    {s.userContacts.length > 0 ?
                        <ul className='invitees'>
                            {s.userContacts.map((contact, index) => (

                                <li key={index}
                                    onClick={this.onContactClick.bind(this, contact._id)}
                                    className={'contact' +
                                        (contactHighlightClass(contact._id))}>
                                    <span className='name'>
                                        {s.eventContactsIds.includes(contact._id) ?
                                            // if it exists place icon, else nothing
                                            <i className="checkmark" /> : null
                                        }
                                        {contact.name}
                                    </span>
                                    <div className='editWrapper'
                                        onClick={(e) => {
                                            p.history.push(`/event/${eventId}/contact/${contact._id}/edit`)
                                            e.stopPropagation()
                                        }}>
                                        <i className='editPenBlue' />
                                    </div>
                                </li>
                            ))}

                        </ul> :
                        <div className='noContactsMs'>You have no contacts saved.</div>
                    }

                    <div className='bottomPusher' />

                </div>

                <div className="footer">
                    <button
                        className='left ghost circular'
                        onClick={() => {
                            p.history.push(`/event/${eventId}/invite`)
                        }}>
                        <i className="left blue arrow" />
                    </button>

                    <button
                        className='right'
                        onClick={this.onSelectClick.bind(this)}>
                        <span>Select</span>
                    </button>
                </div>

            </div>
        )
    }
}

let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactsComponent))
