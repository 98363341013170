//libraries
import React from 'react'

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../../../__redux/actions'
import { withRouter } from 'react-router-dom'

// components
import SelectContact from '../../../elements/SelectContact'

class PayComponent extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            activity: null,
            activityParticipants: [],
            selectedContactToPay: [],
            activityCreator: null,
            error: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true
        let p = this.props
        let that = this
        let activityId = p.match.params.activityId

        fetch(`/api/activities/${activityId}`, {
            method: 'GET',
            // attach token/cookie
            credentials: "same-origin",
        }).then(response => (response.json()))
            .then((activityResult) => {
                if (activityResult.error) {
                    that.setState({ error: activityResult.error })
                }
                if (activityResult._id) {
                    if (this._isMounted) {
                        // when activity is received with id, update state of activity with results
                        this.setState({ activity: activityResult })
                        this.setState({ selectedContactToPay: this.state.selectedContactToPay.concat(activityResult.creator) })
                    }
                }
            })

        fetch(`/api/activitiesParticipants/participantList/${activityId}`, {
            method: 'GET',
            credentials: "same-origin",
        })
            .then(response => (response.json()))
            .then((listOfParticipantsForActivity) => {
                if (this._isMounted && listOfParticipantsForActivity.error) {
                    that.setState({ error: listOfParticipantsForActivity.error })
                } else if (this._isMounted) {
                    this.setState({ activityParticipants: listOfParticipantsForActivity.participants.filter(prt => prt.participantUserId !== p.user._id) })
                }
            })
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    onParticipantClick(participantId) {
        let s = this.state
        if (s.selectedContactToPay.includes(participantId)) {
            this.setState({
                selectedContactToPay: s.selectedContactToPay.filter(id => id !== participantId)
            })
        } else if (s.selectedContactToPay.length === 0) {
            this.setState({
                selectedContactToPay: s.selectedContactToPay.concat(participantId)
            })
        }
    }

    onPayClick() {
        let s = this.state
        let p = this.props
        let activityId = p.match.params.activityId
        let eventId = p.match.params.eventId

        s.selectedContactToPay.length === 1 ?
            fetch(`/api/activitiesParticipants/new/${eventId}/${activityId}`, {
                method: 'POST',
                credentials: 'same-origin',
                headers: { "Content-Type": 'application/json' },
            })
                .then(response => response.json())
                .then(() => {
                    p.history.push(`/event/${eventId}/activity/${activityId}`)
                }) :
            null
    }

    render() {
        let s = this.state
        let p = this.props
        let activityId = p.match.params.activityId
        let eventId = p.match.params.eventId

        return (
            <div className='PayComponent'>

                <SelectContact
                    title='Select contact to pay'
                    contacts={s.activityParticipants}
                    onContactClick={this.onParticipantClick.bind(this)}
                    selectedContacts={s.selectedContactToPay}
                    onBackClick={() => p.history.push(`/event/${eventId}/activity/${activityId}`)}
                    rightButton={{
                        title: s.activity && s.activity.costPerson ? 'Pay ' + s.activity.costPerson : 'Pay',
                        click: this.onPayClick.bind(this),
                        additionalClass: s.selectedContactToPay.length < 1 ? ' disabled' : ''
                    }}
                />
            </div >
        )
    }
}

let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PayComponent))