import update from 'immutability-helper'

export default (temp = {}, action) => {
    switch (action.type) {

    case "setSelectedContacts":
        return update(temp, {
            selectedContacts: { $set: action.contactIds }
        });


    case "cleanSelectedContacts":
        return update(temp, {
            $unset: ['selectedContacts']
        });


    default:
        return temp;
    }
};
