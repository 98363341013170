//libraries
import React from 'react'

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ActionCreators from '../../../__redux/actions'
import { withRouter } from 'react-router-dom'
// import qs from 'query-string'

// components
import DeleteAlert from '../../../elements/DeleteAlert'

class ConfirmDeletionComponent extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            activity: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true
        let p = this.props
        let that = this
        let activityId = p.match.params.activityId

        fetch(`/api/activities/${activityId}`, {
            method: 'GET',
            // attach token/cookie
            credentials: "same-origin",
        }).then(response => (response.json()))
            .then((activityResult) => {
                if (activityResult.error) {
                    that.setState({ error: activityResult.error })
                }
                if (this._isMounted) {
                    // when activity is received with id, update state of activity with results
                    this.setState({ activity: activityResult })
                }
            })
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    onDeleteClick() {
        let p = this.props
        let activityId = p.match.params.activityId
        let eventId = p.match.params.eventId

        fetch(`/api/activities/delete/${activityId}`, {
            method: 'DELETE',
            credentials: 'same-origin',
        })
            .then(response => response.json())
            .then(() => {
                p.history.push('/event/' + eventId)
            })
    }


    render() {
        let p = this.props
        let s = this.state
        let eventId = p.match.params.eventId
        let costPerson = s.activity && s.activity.costPerson

        return (
            <div className='DeleteAlertElement'>

                <DeleteAlert
                    activityCreators={0}
                    refundAlert={`If you are sure, please refund ${costPerson} to each participant from this activity.`}
                    onCancelClick={() => p.history.push(`/event/${eventId}`)}
                    rightButton={{
                        title: 'Delete',
                        click: this.onDeleteClick.bind(this),
                        additionalClass: ''
                    }}
                />
            </div>
        )
    }
}

let mapStateToProps = (state) => (state)
let mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmDeletionComponent))